// import React, { useEffect, useState } from 'react';
// import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
// import { useInView } from 'react-intersection-observer';
// import { motion } from 'framer-motion';

// function LandingSixthSection({ offset, handleShowModal }) {
//     const [refreshToken, setRefreshToken] = useState("");

//     useEffect(() => {
//         setRefreshToken(localStorage.getItem("refresh_token"));
//     }, []);

//     const useStyles = makeStyles((theme) => ({
//         root: {
//             padding: "30px 0rem 68px 0rem",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "flex-start",
//             flexDirection: "column",
//             background: 'background: linear-gradient(110.52deg, #F8FAFC 2.69%, #E9F4FF 100%)',
//         },
//         headingOne: {
//             fontSize: "36px",
//             fontWeight: 700,
//             color: "#000000",
//             textAlign: "start",
//             paddingBottom: '30px', 
//         },
//         headingOneColor: {
//             color: "#0E76BC",
//         },
//         headingOneParagraph: {
//             fontSize: "16px",
//             fontWeight: 400,
//             color: "#000000",
//             textAlign: "start",
//             paddingBottom: '30px', 
//             // maxWidth: '200px'
//         },
//         buttonStartDownload: {
//             padding: "15px 13px",
//             background: "#0E76BC",
//             color: "#fff",
//             borderRadius: "25px",
//             cursor: "pointer",
//             maxWidth: '140px',
//             width: '100%',
//             border: "1px solid #828282",
//             textTransform: "capitalize",
//             "&:hover": {
//                 background: "#00AEEF",
//             },
//         },
//         buttonExplore: {
//             padding: "15px 13px",
//             background: "#fff",
//             color: "#000000",
//             borderRadius: "25px",
//             cursor: "pointer",
//             border: "1px solid #828282",
//             textTransform: "capitalize",
//             "&:hover": {
//                 background: "#E9F4FF",
//             },
//         },
//     }));

//     const classes = useStyles();

//     const { ref: sectionRef, inView: sectionInView } = useInView({
//         triggerOnce: true,
//         threshold: 0.3, // Trigger animation when 30% of the element is visible
//     });

//     const containerVariants = {
//         hidden: { opacity: 0, y: 50 },
//         visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.3 } },
//     };

//     const itemVariants = {
//         hidden: { opacity: 0, x: 50 },
//         visible: { opacity: 1, x: 0 },
//     };

//     return (
//         <motion.div ref={sectionRef} initial="hidden" animate={sectionInView ? "visible" : "hidden"} variants={containerVariants} className={classes.root}>
//             <Container maxWidth="lg">
//                 <Grid container spacing={4} alignItems="center">
//                     <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'start' }}>
//                         <Box display="flex" justifyContent="center" alignItems="start" flexDirection="column" maxWidth={'400px'} width={'100%'}>
//                             <motion.div variants={itemVariants}>
//                                 <Typography variant="h6" className={classes.headingOne}>
//                                     {/* <span className={classes.headingOneColor}>MF</span> Wealth */}
//                                     Smart Portfolios,
//                                     <br />
//                                     Smarter Growth
//                                 </Typography>
//                             </motion.div>
//                             <motion.div variants={itemVariants}>
//                                 <Typography variant="body2" className={classes.headingOneParagraph}>
//                                     Explore multi-asset portfolios personalized to your investment goals, with smart rebalancing to keep you ahead in any market condition.
//                                 </Typography>
//                             </motion.div>
//                             <motion.div variants={itemVariants} style={{width: '100%'}}>
//                                 <Button className={classes.buttonStartDownload}>
//                                     Join Now
//                                 </Button>
//                             </motion.div>
//                         </Box>

//                     </Grid>
//                     <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>

//                         <Box mt={2} textAlign="center" position="relative" display="inline-block" >
//                             {/* Rotating Background Image */}
//                             <motion.img
//                                 src="/images/Intro_Page/Ellipses.svg"
//                                 alt="MF Wealth"
//                                 style={{
//                                     maxWidth: '650px',
//                                     width: 'auto',
//                                     position: 'absolute',
//                                     top: '-10px',
//                                     left: '-180px',
//                                     // transform: 'translate(-50%, -50%)',
//                                     zIndex: 1,
//                                 }}
//                                 animate={{ rotate: 360 }}
//                                 transition={{ repeat: Infinity, duration: 10, ease: "linear" }}
//                             />

//                             {/* Foreground Device Image */}
//                             <motion.img
//                                 src="/images/Intro_Page/Device 13PM.svg"
//                                 alt="Mobile Illustration"
//                                 style={{
//                                     maxWidth: '605px',
//                                     width: 'auto',
//                                     position: 'relative',
//                                     zIndex: 2,
//                                     // mixBlendMode: 'multiply', // or 'screen', 'overlay', 'soft-light'
//                                 }}
//                                 variants={itemVariants}
//                             />
//                         </Box>

//                     </Grid>
//                 </Grid>
//             </Container>
//         </motion.div>
//     );
// }

// export default LandingSixthSection;


import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { namespace } from '../../environment';

function LandingSixthSection() {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: "60px 0",
            background: "linear-gradient(110.52deg, #F8FAFC 2.69%, #E9F4FF 100%)",
            display: "flex",
            alignItems: "center",
        },
        heading: {
            fontSize: "32px",
            fontWeight: 700,
            color: "#000",
            textAlign: "start",
            paddingBottom: "20px",
        },
        paragraph: {
            fontSize: "16px",
            fontWeight: 400,
            color: "#000",
            textAlign: "start",
            paddingBottom: "30px",
        },
        buttonExplore: {
            padding: "14px 24px",
            background: "#0E76BC",
            color: "#fff",
            borderRadius: "25px",
            cursor: "pointer",
            border: "none",
            textTransform: "capitalize",
            "&:hover": {
                background: "#00AEEF",
            },
        },
        circleContainer: {
            position: "relative",
            width: "100%",
            maxWidth: '600px',
            height: '600px',
            // height: "100%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "visible",  // Important to prevent clipping

            [theme.breakpoints.down("md")]: {
                maxWidth: "400px",
                height: "400px",
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "350px",
                height: "350px",
            },
        },
        circleBackground: {
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            background: "#00AEEF",
            // zIndex: 1,
            zIndex: 0,  // Ensure it's behind the images
            opacity: 1, // Ensure it is visible
        },
        imageBox: {
            position: "absolute",
            width: "266px",
            height: "266px",
            borderRadius: "20px",
            overflow: "hidden",
            zIndex: 2,
            [theme.breakpoints.down("md")]: {
                width: "180px",
                height: "180px",
            },
            [theme.breakpoints.down("sm")]: {
                width: "150px",
                height: "150px",
            },
            [theme.breakpoints.down("xs")]: {
                width: "140px",
                height: "140px",
            },
        },
        img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        container: {
            display: "flex",
            flexDirection: "row", // Default order for desktop
            alignItems: "center",
            // [theme.breakpoints.down("sm")]: {
            //     flexDirection: "column-reverse", // Reverse order for mobile
            // },
        },
        containerShow: {
            display: 'none'
        },
        containerHide: {
            display: 'block'
        },
        "@media (max-width: 1024px)": {
            // root: {
            //     backgroundImage: 'url("/images/Intro_Page/Design your portfolio.svg")',
            // }
        },
        "@media (max-width: 768px)": {
            // root: {
            //     backgroundImage: 'url("/images/Intro_Page/Design your portfolio.svg")',
            // }
            // circleBackground: {
            //     maxWidth: '600px !important',
            //     width: "100%",
            //     position: "block",
            // }
            containerHide: {
                display: 'none'
            },
            containerShow: {
                display: 'block'
            }
        },
        "@media (max-width: 480px)": {
            // circleBackground: {
            //     maxWidth: '600px !important',
            //     width: "100%",
            //     position: "block",
            // }
            containerHide: {
                display: 'none'
            },
            containerShow: {
                display: 'block'
            }
        }

    }));

    const classes = useStyles();
    const navigate = useNavigate();


    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const itemVariants = {
        hidden: { opacity: 0, y: 30 },
        // visible: { opacity: 1, y: 0 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.5, // Delay each child element by 0.5 seconds
            },
        },
    };

    const itemVariantsNot = {
        hidden: { opacity: 0, y: 0 },
        // visible: { opacity: 1, y: 0 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.5, // Delay each child element by 0.5 seconds
            },
        },
    };

    const itemVariantsMove = {
        hidden: { opacity: 0, x: 100 },
        // visible: { opacity: 1, y: 0 },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                staggerChildren: 0.5, // Delay each child element by 0.5 seconds
            },
        },
    };

    return (
        <motion.div ref={sectionRef} initial="hidden" animate={sectionInView ? "visible" : "hidden"} variants={{
            visible: { transition: { staggerChildren: 0.2 } },
            hidden: {}
        }} className={classes.root}>
            <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="center" className={classes.container}>
                    <Grid item xs={12} md={6} className={classes.containerHide}>
                        <motion.div variants={itemVariantsMove} className={classes.circleContainer}>
                            <div className={classes.circleBackground}></div>
                            <motion.div variants={itemVariantsNot}>

                                <div className={classes.imageBox} style={{ top: "10%", left: "4%" }}>
                                    <img src="/images/Intro_Page/Rectangle 4391.svg" alt="Team1" className={classes.img} />
                                </div>
                            </motion.div>
                            <motion.div variants={itemVariantsNot}>

                                <div className={classes.imageBox} style={{ bottom: "00%", left: "4%" }}>
                                    <img src="/images/Intro_Page/Rectangle 4390.svg" alt="Team2" className={classes.img} />
                                </div>
                            </motion.div>
                            <motion.div variants={itemVariantsNot}>
                                <div className={classes.imageBox} style={{ top: "0%", right: "5%" }}>
                                    <img src="/images/Intro_Page/Rectangle 4393.svg" alt="Team3" className={classes.img} />
                                </div>
                            </motion.div>
                            <motion.div variants={itemVariantsNot}>
                                <div className={classes.imageBox} style={{ bottom: "10%", right: "5%" }}>
                                    <img src="/images/Intro_Page/Rectangle 4392.svg" alt="Team4" className={classes.img} />
                                </div>
                            </motion.div>
                        </motion.div>
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <motion.div variants={itemVariants}>
                            <Typography className={classes.heading}>
                                Invest in the Hottest Sectors, <br /> Backed by Expertise
                            </Typography>
                        </motion.div>
                        <motion.div variants={itemVariants}>
                            <Typography className={classes.paragraph}>
                                Let our wealth team guide you with tailored advice to capitalize on the latest market trends.
                            </Typography>
                        </motion.div>
                        <motion.div variants={itemVariants}>
                            <Button className={classes.buttonExplore}
                                onClick={() => {
                                    navigate(`${namespace}/user`);
                                }}>
                                Explore Opportunities <ArrowForwardIcon style={{ marginLeft: '5px' }} />
                            </Button>
                        </motion.div>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.containerShow}>
                        <motion.div variants={itemVariantsMove} className={classes.circleContainer}>
                            <div className={classes.circleBackground}></div>
                            <motion.div variants={itemVariantsNot}>

                                <div className={classes.imageBox} style={{ top: "10%", left: "4%" }}>
                                    <img src="/images/Intro_Page/Rectangle 4391.svg" alt="Team1" className={classes.img} />
                                </div>
                            </motion.div>
                            <motion.div variants={itemVariantsNot}>

                                <div className={classes.imageBox} style={{ bottom: "00%", left: "4%" }}>
                                    <img src="/images/Intro_Page/Rectangle 4390.svg" alt="Team2" className={classes.img} />
                                </div>
                            </motion.div>
                            <motion.div variants={itemVariantsNot}>
                                <div className={classes.imageBox} style={{ top: "0%", right: "5%" }}>
                                    <img src="/images/Intro_Page/Rectangle 4393.svg" alt="Team3" className={classes.img} />
                                </div>
                            </motion.div>
                            <motion.div variants={itemVariantsNot}>
                                <div className={classes.imageBox} style={{ bottom: "10%", right: "5%" }}>
                                    <img src="/images/Intro_Page/Rectangle 4392.svg" alt="Team4" className={classes.img} />
                                </div>
                            </motion.div>
                        </motion.div>
                    </Grid>
                </Grid>
            </Container>
        </motion.div>
    );
}

export default LandingSixthSection;
