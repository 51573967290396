import { useEffect, useState } from "react";

const UseStoreLink = () => {
    const [storeLink, setStoreLink] = useState("");

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();

        if (/android/i.test(userAgent)) {
            setStoreLink("https://play.google.com/store/apps/details?id=com.mfwealth.mfwealth");
        } else if (/iphone|ipad|ipod/i.test(userAgent)) {
            setStoreLink("https://apps.apple.com/in/app/mf-wealth/id6738738300");
        } else if (/macintosh|mac os x/i.test(userAgent)) {
            setStoreLink("https://apps.apple.com/in/app/mf-wealth/id6738738300");
        } else if (/windows/i.test(userAgent)) {
            setStoreLink("https://play.google.com/store/apps/details?id=com.mfwealth.mfwealth");
        } else {
            setStoreLink("https://play.google.com/store/apps/details?id=com.mfwealth.mfwealth"); // Default fallback
        }
    }, []);

    return storeLink;
};

export default UseStoreLink;
