import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { namespace } from '../../environment';
import { useNavigate } from 'react-router-dom';

function LandingThirdSection({ offset, handleShowModal }) {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: "30px 0rem 68px 0rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            background: 'background: linear-gradient(110.52deg, #F8FAFC 2.69%, #E9F4FF 100%)',
        },
        headingOne: {
            fontSize: "36px",
            fontWeight: 700,
            color: "#000000",
            textAlign: "start",
            paddingBottom: '30px',
        },
        headingOneColor: {
            color: "#0E76BC",
        },
        headingOneParagraph: {
            fontSize: "16px",
            fontWeight: 400,
            color: "#000000",
            textAlign: "start",
            paddingBottom: '30px',
            // maxWidth: '200px'
        },
        buttonStartDownload: {
            padding: "15px 13px",
            background: "#0E76BC",
            color: "#fff",
            borderRadius: "25px",
            cursor: "pointer",
            maxWidth: '140px',
            width: '100%',
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#00AEEF",
            },
        },
        buttonExplore: {
            padding: "15px 13px",
            background: "#fff",
            color: "#000000",
            borderRadius: "25px",
            cursor: "pointer",
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#E9F4FF",
            },
        },
        ellipseMove: {
            maxWidth: '650px',
            width: 'auto',
        },
        ellipseMoveImg: {
            maxWidth: '605px',
            width: 'auto',
        },
        "@media (max-width: 1024px)": {

        },
        "@media (max-width: 768px)": {
            headingOne: {
                fontSize: "24px",
            },
            headingOneParagraph: {
                fontSize: "12px",
            },
            // ellipseMove: {
            //     maxWidth: '650px',
            //     width: '100%',
            // },
            ellipseMoveImg: {
                maxWidth: '500px',
                width: '100%',
            }
        },
        "@media (max-width: 480px)": {

        }
    }));

    const classes = useStyles();
    const navigate = useNavigate();

    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.3, // Trigger animation when 30% of the element is visible
    });

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.3 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: { opacity: 1, x: 0 },
    };

    return (
        <motion.div ref={sectionRef} initial="hidden" animate={sectionInView ? "visible" : "hidden"} variants={containerVariants} className={classes.root}>
            <Container maxWidth="lg" style={{ overflow: 'hidden' }}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'start' }}>
                        <Box display="flex" justifyContent="center" alignItems="start" flexDirection="column" maxWidth={'400px'} width={'100%'}>
                            <motion.div variants={itemVariants}>
                                <Typography variant="h6" className={classes.headingOne}>
                                    {/* <span className={classes.headingOneColor}>MF</span> Wealth */}
                                    Smart Portfolios,
                                    <br />
                                    Smarter Growth
                                </Typography>
                            </motion.div>
                            <motion.div variants={itemVariants}>
                                <Typography variant="body2" className={classes.headingOneParagraph}>
                                    Explore multi-asset portfolios personalized to your investment goals, with smart rebalancing to keep you ahead in any market condition.
                                </Typography>
                            </motion.div>
                            <motion.div variants={itemVariants} style={{ width: '100%' }}>
                                <Button className={classes.buttonStartDownload}
                                    onClick={() => {
                                        navigate(`${namespace}/user`);
                                    }}>
                                    Join Now
                                </Button>
                            </motion.div>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>

                        <Box mt={2} textAlign="center" position="relative" display="inline-block" >
                            {/* Rotating Background Image */}
                            <motion.img
                                src="/images/Intro_Page/Ellipses.svg"
                                alt="MF Wealth"
                                style={{
                                    
                                    position: 'absolute',
                                    top: '-10px',
                                    left: '-180px',
                                    // transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                }}
                                animate={{ rotate: 360 }}
                                className={classes.ellipseMove}
                                transition={{ repeat: Infinity, duration: 10, ease: "linear" }}
                            />

                            {/* Foreground Device Image */}
                            <motion.img
                                src="/images/Intro_Page/Device 13PM.svg"
                                alt="Mobile Illustration"
                                style={{

                                    position: 'relative',
                                    zIndex: 2,
                                    // mixBlendMode: 'multiply', // or 'screen', 'overlay', 'soft-light'
                                }}
                                className={classes.ellipseMoveImg}
                                variants={itemVariants}
                            />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </motion.div>
    );
}

export default LandingThirdSection;
