import React, { useState, useEffect } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Total_Invest from "./Total_Invest";
import FundTable from "./FundTable";
import SipList from "./SipList";
import ContuinueInvestmentJourney from "./ContuinueInvestmentJourney.js";
import {
  GET_FOLIO_HOLDINGS,
  GET_PRIMARY_BANK,
  GET_ALL_BANK,
  GET_ASSISTED_HOLDINGS,
  GET_USER_ONBOARDING_STATUS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ReactLoading from "react-loading";
import OrderProgress from "./OrderProgress/index.js";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { namespace } from "../../../environment";
import { Skeleton } from "@mui/material";

const Index = () => {
  const useStyles = makeStyles({
    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    primaryButton: {
      padding: "0.7rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      fontWeight: 600,
      borderRadius: "5px",
      border: "none",
      outline: "none",
    },
    button: {
      background: "#EAEAEA",
      padding: "0.7rem 3rem",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      outline: "none",
    },
    buttonBox: {
      background: "#EAEAEA",
      borderRadius: "8px",
      padding: "2px 5px",
    },
    "@media (max-width:600px)": {
      button: {
        padding: "0.7rem 0.5rem",
      },
      primaryButton: {
        padding: "0.7rem 0.5rem",
      },
    },
  });
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const category = searchParams.get("category");

  const { userStore } = useSelector((state) => state);

  const [holdings, setHoldings] = useState(null);
  const [bankData, setBankData] = useState("");
  const [loading, setLoading] = useState(true);
  const [onboardingData, setOnboardingData] = useState(null);
  const [button, setButton] = useState(
    category ? category : sessionStorage.getItem("category")
  );

  const navigate = useNavigate();

  const [holdingDataFromApi, setHoldingDataFromApi] = useState(null)
  const holdingsData = (data) => {
    setHoldingDataFromApi(data)
  }



  const get_holdings = async () => {
    setLoading(true);
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      // params: { userId: localStorage.getItem("user_id") },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      // const response = await httpInstance(
      //   `${button == "diy" ? GET_FOLIO_HOLDINGS : GET_ASSISTED_HOLDINGS}`,
      const response = await httpInstance(GET_FOLIO_HOLDINGS, httpConfig);
      setHoldings(response?.data?.data);
      // const bank = await httpInstance(`${GET_PRIMARY_BANK}`, httpConfig1);
      const bank = await httpInstance(`${GET_ALL_BANK}`, httpConfig1);
      setBankData(bank.data?.data);
      const userData = await httpInstance(`${GET_USER_ONBOARDING_STATUS}`, httpConfig1);
      setOnboardingData(userData.data?.data);
      setLoading(false);

    } catch {
      setLoading(false);
    }
  };

  //----------------------------------set params-----------------------------------------------------//
  // const set_params = () => {
  //   sessionStorage.setItem("category", button);
  // };

  // //navigate
  // const handle_navigate = () => {
  //   navigate({
  //     pathname: `${namespace}/myportfolio`,
  //     search: `?${createSearchParams({
  //       tab: "mutualfund",
  //       category: button,
  //     })}`,
  //   });
  // };

  //----------------------------------------------------------------//
  useEffect(() => {
    // set_params();
    // handle_navigate();
    get_holdings();
  }, [button]);

  //-------------------------------handle button-----------------------------------------//
  const handle_button = (value) => {
    setButton(value);
  };

  return (
    <div>
      <Box px={{ xs: 2, md: 13 }}>
        <Box display="flex" justifyContent="center" my={3}>
          {userStore?.user?.isAssistedUser && (
            <Box className={classes.buttonBox}>
              <button
                className={
                  button === "diy" ? classes.primaryButton : classes.button
                }
                onClick={() => handle_button("diy")}
              >
                Do it yourself
              </button>
              <button
                className={
                  button === "assisted" ? classes.primaryButton : classes.button
                }
                onClick={() => handle_button("assisted")}
              >
                Assisted Services
              </button>
            </Box>
          )}
        </Box>
        <Typography className={classes.title}>My Mutual Funds</Typography>

        {!loading ? (
          (holdings && onboardingData?.is_investment_done) ? (
            <>
              <Total_Invest holdings={holdings} category={button}
                bankData={bankData}
              />
              {/* <FundTable
                holdings={holdings}
                bankData={bankData}
                category={button}
                holdingsData={holdingDataFromApi}
              /> */}
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              my={4}
            >
              <img
                src="/images/undraw_book_reading_re_fu2c 1.svg"
                height="175px"
                width="175px"
              />
              <Typography className={classes.title}>
                Your Portfolio is empty
              </Typography>
            </Box>
          )
        ) : (
          <Box display="flex" justifyContent="center" mt={3}>
            {/* <ReactLoading
              type="spinningBubbles"
              color="#0E76BC"
              height={"4%"}
              width={"4%"}
            /> */}
            <Skeleton variant="rounded" border width={'100%'} height={'200px'} />

          </Box>
        )}
        {/* <OrderProgress category={button} /> */}
        {/* {button} */}
        {/* {button == "diy" && <SipList holdingsData={holdingsData} />}
        {button == "diy" && <ContuinueInvestmentJourney />} */}
        <SipList holdingsData={holdingsData} category={category} bankData={bankData} />
        <ContuinueInvestmentJourney />
      </Box>
    </div>
  );
};

export default Index;
