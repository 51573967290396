import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Height } from '@mui/icons-material';

function LandingFourthSection({ offset, handleShowModal }) {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: "30px 0rem 68px 0rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            background: '#0E76BC',
        },
        headingOne: {
            fontSize: "36px",
            fontWeight: 700,
            color: "#FFFFFF",
            textAlign: "center",
            paddingBottom: '30px',
        },
        headingOneColor: {
            color: "#0E76BC",
        },
        headingOneParagraph: {
            fontSize: "16px",
            fontWeight: 400,
            color: "#FFFFFF",
            textAlign: "center",
            paddingBottom: '30px',
            // maxWidth: '200px'
        },
        headingOneSubParagraph: {
            fontSize: "20px",
            fontWeight: 400,
            color: "#FFFFFF",
            textAlign: "center",
            paddingBottom: '30px',
            // maxWidth: '200px'
        },
        buttonStartDownload: {
            padding: "15px 13px",
            background: "#0E76BC",
            color: "#fff",
            borderRadius: "25px",
            cursor: "pointer",
            maxWidth: '140px',
            width: '100%',
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#00AEEF",
            },
        },
        buttonExplore: {
            padding: "15px 13px",
            background: "#fff",
            color: "#000000",
            borderRadius: "25px",
            cursor: "pointer",
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#E9F4FF",
            },
        },
        listFloatingNormalDiv: {
            display: 'none ',
        },
        "@media (max-width: 1024px)": {
            listFloatingNormalDiv: {
                display: 'flex ',
            },
            headingOneSubParagraph: {
                paddingBottom: '10px',
            },
            listFloatingDiv: {
                display: 'none !important',
            }
            
        },
        "@media (max-width: 768px)": {
            listFloatingNormalDiv: {
                display: 'flex ',
            },
            headingOneSubParagraph: {
                paddingBottom: '10px',
            },
            listFloatingDiv: {
                display: 'none !important',

            },
            listFloatingNormalDivImg: {
                maxWidth: 'auto !important',
                width: '100% !important',
            }

        },
        "@media (max-width: 480px)": {
            listFloatingNormalDiv: {
                display: 'flex ',
            },
            headingOneSubParagraph: {
                paddingBottom: '10px',
            },
            listFloatingDiv: {
                display: 'none !important',

            },
            listFloatingNormalDivImg: {
                maxWidth: 'auto !important',
                width: '100% !important',
            }
        }
    }));

    const classes = useStyles();

    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.3, // Trigger animation when 30% of the element is visible
    });

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.3 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: { opacity: 1, x: 0 },
    };

    const floatingAnimation = {
        visible: {
            y: [0, -10, 0], // Move up and down
            transition: {
                duration: 2, // Time for one cycle
                repeat: Infinity, // Loop the animation infinitely
                ease: "easeInOut", // Smooth easing
            },
        },
    };

    return (
        <motion.div ref={sectionRef} initial="hidden" animate={sectionInView ? "visible" : "hidden"} variants={containerVariants} className={classes.root}>
            <Container maxWidth="lg" style={{ overflow: 'hidden' }}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                    <Box maxWidth={'800px'} width={'100%'}>

                        <motion.div variants={itemVariants}>
                            <Typography variant="h6" className={classes.headingOne}>
                                Your all-in-one digital companion for mutual fund investments and tracking
                            </Typography>
                        </motion.div>
                    </Box>
                    <Box maxWidth={'810px'} width={'100%'}>

                        <motion.div variants={itemVariants}>
                            <Typography variant="h6" className={classes.headingOneParagraph}>
                                MF Wealth is a one-stop solution that makes it extremely easy and secure for you to invest, manage,
                                and grow your portfolio fully digital environment. Whether you are a novice or experienced investor,
                                we empower you to make smarter financial decisions anytime, anywhere.
                            </Typography>
                        </motion.div>
                    </Box>
                    <Box className={classes.listFloatingNormalDiv} justifyContent={'start'} width={'100%'} >
                        <Box display={'flex'} justifyContent={'start'} alignItems={'start'} flexDirection={'column'}>
                            <motion.div
                                variants={itemVariants}
                                animate="visible" // Trigger the floating animation
                            // style={{ height: '60%', display: 'flex', alignItems: 'center', }}
                            >
                                <Typography variant="h6" className={classes.headingOneSubParagraph} >
                                    &#8594;  25k+ transactions till date
                                </Typography>
                            </motion.div>
                            <motion.div
                                variants={itemVariants}
                                animate="visible"
                            >
                                <Typography variant="h6" className={classes.headingOneSubParagraph}>
                                    &#8594; 2K+ clients across 30+ Indian cities
                                </Typography>
                            </motion.div>
                            <motion.div
                                variants={itemVariants}
                                animate="visible"
                            // style={{ height: '60%', display: 'flex', alignItems: 'center' }}
                            >
                                <Typography variant="h6" className={classes.headingOneSubParagraph} >
                                    &#8594; Fastest growing AUM
                                </Typography>
                            </motion.div>
                            <motion.div
                                variants={itemVariants}
                                animate="visible"
                            // style={{ marginTop: '10%' }}

                            >
                                <Typography variant="h6" className={classes.headingOneSubParagraph}>
                                    &#8594; Managing AUM of over 500 cr
                                </Typography>
                            </motion.div>
                        </Box>
                    </Box>
                    <Box mt={2} textAlign="center" position="relative" display="inline-block" width={'100%'}  >

                        <motion.div
                            style={{
                                // maxWidth: '650px',
                                width: '100%',
                                height: '100%',
                                // width: 'auto',
                                position: 'absolute',
                                top: '-0px',
                                left: '-0px',
                                zIndex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            variants={itemVariants}
                            className={classes.listFloatingDiv}
                        >
                            <Box display={'flex'} justifyContent={'space-between'} height={'100%'} width={'90%'} position={'absolute'} left={'30px'}>
                                <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'end'} flexDirection={'column'}>
                                    <motion.div
                                        variants={floatingAnimation}
                                        animate="visible" // Trigger the floating animation
                                        style={{ height: '60%', display: 'flex', alignItems: 'center', }}
                                    >
                                        <Typography variant="h6" className={classes.headingOneSubParagraph} >
                                            25k+ transactions till date
                                        </Typography>
                                    </motion.div>
                                    <motion.div
                                        variants={floatingAnimation}
                                        animate="visible"
                                    >
                                        <Typography variant="h6" className={classes.headingOneSubParagraph}>
                                            2K+ clients across 30+ Indian cities
                                        </Typography>
                                    </motion.div>
                                </Box>
                                <Box height={'90%'} display={'flex'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
                                    <motion.div
                                        variants={floatingAnimation}
                                        animate="visible"
                                        style={{ height: '60%', display: 'flex', alignItems: 'center' }}
                                    >
                                        <Typography variant="h6" className={classes.headingOneSubParagraph} >
                                            Fastest growing AUM
                                        </Typography>
                                    </motion.div>
                                    <motion.div
                                        variants={floatingAnimation}
                                        animate="visible"
                                        style={{ marginTop: '10%' }}

                                    >
                                        <Typography variant="h6" className={classes.headingOneSubParagraph}>
                                            Managing AUM of over 500 cr
                                        </Typography>
                                    </motion.div>
                                </Box>
                            </Box>
                        </motion.div>

                        <motion.img
                            src="/images/Intro_Page/Device 13PM Fourth.svg"
                            alt="Mobile Illustration"
                            style={{
                                maxWidth: '605px',
                                width: 'auto',
                                position: 'relative',
                                zIndex: 2,
                                // mixBlendMode: 'multiply', // or 'screen', 'overlay', 'soft-light'
                            }}
                            variants={itemVariants}
                            className={classes.listFloatingNormalDivImg}
                        />
                    </Box>
                </Box>
                {/* <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'start' }}>
                        <Box display="flex" justifyContent="center" alignItems="start" flexDirection="column" maxWidth={'400px'} width={'100%'}>
                            <motion.div variants={itemVariants}>
                                <Typography variant="h6" className={classes.headingOne}>
                                    Smart Portfolios,
                                    <br />
                                    Smarter Growth
                                </Typography>
                            </motion.div>
                            <motion.div variants={itemVariants}>
                                <Typography variant="body2" className={classes.headingOneParagraph}>
                                    Explore multi-asset portfolios personalized to your investment goals, with smart rebalancing to keep you ahead in any market condition.
                                </Typography>
                            </motion.div>
                            <motion.div variants={itemVariants} style={{ width: '100%' }}>
                                <Button className={classes.buttonStartDownload}>
                                    Join Now
                                </Button>
                            </motion.div>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>

                        <Box mt={2} textAlign="center" position="relative" display="inline-block" >
                            <motion.img
                                src="/images/Intro_Page/Ellipses.svg"
                                alt="MF Wealth"
                                style={{
                                    maxWidth: '650px',
                                    width: 'auto',
                                    position: 'absolute',
                                    top: '-10px',
                                    left: '-180px',
                                    zIndex: 1,
                                }}
                                animate={{ rotate: 360 }}
                                transition={{ repeat: Infinity, duration: 10, ease: "linear" }}
                            />


                        </Box>

                    </Grid>
                </Grid> */}
            </Container>
        </motion.div>
    );
}

export default LandingFourthSection;
