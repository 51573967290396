// import React, { useEffect, useState } from 'react';
// import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
// import { motion } from "framer-motion";

// function LandingTwelvethSection({ offset, handleShowModal }) {
//   const [refreshToken, setRefreshToken] = useState("");
//   useEffect(() => {
//     setRefreshToken(localStorage.getItem("refresh_token"));
//   }, []);

//   const useStyles = makeStyles((theme) => ({
//     root: {
//       background: "#FFFFFF",
//       padding: "50px 0rem 55px 0rem",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "flex-start",
//       flexDirection: "column",
//     },
//     headingOne: {
//       fontSize: "48px",
//       fontWeight: 600,
//       color: "#0E76BC",
//       marginBottom: "1.2rem",
//       textAlign: "center",
//     },
//     boxShadow: {
//       background: '#042338',
//       boxShadow: '0px 4px 4px 0px #00000040',
//       minWidth: '110px',
//       borderRadius: '1px',
//     },
//     boxShadowSubHeadingOne: {
//       fontFamily: "Poppins",
//       fontSize: '24px',
//       fontWeight: 600,
//       textAlign: 'start',
//       color: "#0E76BC",
//       padding: '6px 0rem 6px 0rem',
//       marginTop: '-6px'
//     },
//     boxShadowSubBox: {
//       background: '#042338',
//       padding: '20px 16px',
//       gap: '10px',
//     },
//     boxShadowSubBoxStartData: {
//       fontFamily: "Poppins",
//       fontSize: '20px',
//       fontWeight: 400,
//       textAlign: 'start',
//       color: "#FFFFFF",
//     },
//     "@media (max-width: 900px)": {
//       root: {
//         padding: "8rem 0rem 1rem 0rem",
//       },
//       headingOne: {
//         fontSize: "1.6rem",
//       },
//     },
//   }));

//   const classes = useStyles();

//   // Animation variants
//   const sectionVariants = {
//     hidden: { opacity: 0, y: 80 },
//     visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
//   };

//   const cardVariants = {
//     hidden: { opacity: 0, y: 40 },
//     visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
//   };

//   return (
//     <motion.div
//       className={classes.root}
//       variants={sectionVariants}
//       initial="hidden"
//       whileInView="visible"
//       viewport={{ once: true, amount: 0.2 }}
//     >
//       <Box margin={'31px 27px'} width={'100%'}>
//         <Grid container spacing={0} alignItems="center" justifyContent="between">
//           {[
//             {
//               img: "/images/Rectangle 28.svg",
//               title: "How mutual funds as low-cost products help",
//               desc: "In the realm of personal finance, dreams often seem distant and unattainable, particularly...."
//             },
//             {
//               img: "/images/Rectangle 28 (1).svg",
//               title: "Spread your risks across sector funds",
//               desc: "As the benchmark equity indices touch all-time highs, investors are looking for value in.."
//             },
//             {
//               img: "/images/Rectangle 28 (2).svg",
//               title: "Short-term investing: Options to park ....",
//               desc: "Fixed deposits serve as an attractive option for short-term investments...."
//             }
//           ].map((card, index) => (
//             <Grid key={index} item xs={12} md={4} style={{ paddingTop: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//               <motion.div
//                 variants={cardVariants}
//                 initial="hidden"
//                 whileInView="visible"
//                 viewport={{ once: true, amount: 0.3 }}
//               >
//                 <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} maxWidth={"340px"} overflow={'hidden'}>
//                   <img src={card.img} alt="Section Card" width="342px" style={{ marginLeft: '-2px' }} />
//                   <Box className={classes.boxShadowSubBox}>
//                     <Typography variant="h4" className={classes.boxShadowSubHeadingOne}>
//                       {card.title}
//                     </Typography>
//                     <Typography variant="h4" className={classes.boxShadowSubBoxStartData}>
//                       {card.desc}
//                     </Typography>
//                   </Box>
//                 </Box>
//               </motion.div>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//     </motion.div>
//   );
// }

// export default LandingTwelvethSection;



// LandingTwelvethSection.js
import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles, Card, CardContent } from "@material-ui/core";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";



function LandingTwelvethSection({ offset, handleShowModal }) {
    const [refreshToken, setRefreshToken] = useState("");
    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, [localStorage]);
    // console.log(refreshToken);
    const useStyles = makeStyles((theme) => ({
        root: {
            // backgroundImage: "url('./images/introBG.svg')",
            background: "#FFFFFF",
            padding: "50px 0rem 55px 0rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",

        },
        headingOne: {
            fontSize: "48px",
            fontWeight: 600,
            color: "#0E76BC",
            marginBottom: "1.2rem",
            textAlign: "center",
        },
        headingOneColor: {
            color: "#0E76BC",
        },
        headingTwo: {
            fontSize: "16px",
            fontWeight: 400,
            textAlign: "start",
            color: "#FFFFFF",
            padding: "0px 10px 20px 0px",
            opacity: 0.8,
        },
        headingThree: {
            fontFamily: 'Roboto',
            fontSize: "20px",
            fontWeight: 400,
            textAlign: "start",
            color: "#E6F1F8",
            padding: "0px 0px 5px 0px",
            opacity: 0.8,
        },
        button: {
            padding: "1rem 4rem",
            background: "#0E76BC",
            color: "#FFFFFF",
            borderRadius: "5px",
            border: "none",
            margin: "0 1.8rem 0 0",
            cursor: "pointer",
            fontSize: "18px",
            fontWeight: 600,
        },

        boxShadow: {
            background: '#042338',
            boxShadow: ' 0px 4px 4px 0px #00000040',
            minWidth: '110px',
            borderRadius: '1px',
        },
        boxShadowNumber: {
            fontFamily: "Poppins",
            fontSize: '20px',
            fontWeight: 400,
            textAlign: 'center',
            color: "#000000",
        },
        // boxShadowData: {
        //   fontFamily: "Poppins",
        //   fontSize: '14px',
        //   fontWeight: 700,
        //   textAlign: 'center',
        //   color: "#0E76BC",
        // },
        boxShadowSubHeadingOne: {
            fontFamily: "Poppins",
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'start',
            color: "#0E76BC",
            padding: '6px 0rem 6px 0rem',
            marginTop: '-6px'
        },
        boxShadowSubBox: {
            background: '#042338',
            padding: ' 20px 16px 20px 16px',
            gap: '10px',
        },
        boxShadowSubBoxStartData: {
            fontFamily: "Poppins",
            fontSize: '20px',
            fontWeight: 400,
            textAlign: 'start',
            color: "#FFFFFF",
        },
        boxShadowSubBoxEndData: {
            fontFamily: "Poppins",
            fontSize: '18px',
            fontWeight: 600,
            textAlign: 'center',
            color: "#9EC8E4",
        },
        boxShadowSubBoxButton: {
            fontFamily: "Poppins",
            fontSize: '18px',
            fontWeight: 600,
            textAlign: 'center',
            color: "#0E76BC",
            boxShadow: "0px 4px 4px 0px #00000040",
            backgroundColor: "#FFFFFF",
            width: '100%',
            // margin: ' 20px 16px 20px 16px',
            "&:hover": {
                backgroundColor: "#0E76BC",
                color: "#FFFFFF",
            }
        },
        "@media (max-width: 900px)": {
            root: {
                padding: "0rem 0rem 1rem 0rem",
            },
            headingOne: {
                fontSize: "1.6rem",
            },
            headingTwo: {
                fontSize: "12px",
            },
        },
    }));
    const classes = useStyles();

    const containerVariants = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    //   return (
    //     <div className={classes.root} height="100%">
    //       {/* <Container maxWidth="lg"> */}
    //       {/* <Box style={{ height: '0px', display: 'flex', justifyContent: 'end',  }}>
    //           <img src="/images/floatingFiveOne.svg" alt="Section 2" width="153.43px" height='100px' className='img-fluid' style={{ position: 'relative', bottom: '60px', left: '142px' }} />
    //         </Box>
    //         <Box display='flex' justifyContent={'center'} >
    //           <Box maxWidth={'500px'}>

    //             <Typography variant="h4" component="div" gutterBottom className={classes.headingOne} >
    //               How mutual funds as low-cost products help
    //             </Typography>
    //             <img src="/images/StaticWebDesignIcon.svg" alt="Section 2" width="100%" height='30px' className='img-fluid' />

    //           </Box>
    //         </Box> */}
    //       <Box margin={'31px 27px'} width={'100%'}>
    //         <Grid container spacing={0} alignItems="center" justifyContent='between'>
    //           <Grid item xs={12} md={4} style={{ paddingTop: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //             <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} maxWidth={"340px"} overflow={'hidden'}>
    //               <Box padding={'0px 0px'}>
    //                 <img src="/images/Rectangle 28.svg" alt="Section 2" width="342px" height='100%' className='img-fluid' style={{ marginLeft: '-2px' }} />
    //                 <Box className={classes.boxShadowSubBox}>
    //                   <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} >
    //                     How mutual funds as low-cost products help
    //                   </Typography>
    //                   <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} >
    //                     In the realm of personal finance, dreams often seem distant and unattainable, particularly....
    //                   </Typography>
    //                 </Box>
    //               </Box>
    //             </Box>
    //           </Grid>
    //           <Grid item xs={12} md={4} style={{ paddingTop: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //             <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} maxWidth={"340px"} overflow={'hidden'}>
    //               <Box padding={'0px 0px'}>
    //                 <img src="/images/Rectangle 28 (1).svg" alt="Section 2" width="342px" height='100%' className='img-fluid' style={{ marginLeft: '-2px' }} />
    //                 <Box className={classes.boxShadowSubBox}>
    //                   <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} >
    //                     Spread your risks across sector funds
    //                   </Typography>
    //                   <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} >
    //                     As the benchmark equity indices touch all-time highs, investors are looking for value in..
    //                   </Typography>
    //                 </Box>
    //               </Box>
    //             </Box>
    //           </Grid>
    //           <Grid item xs={12} md={4} style={{ paddingTop: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //             <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} maxWidth={"340px"} overflow={'hidden'}>
    //               <Box padding={'0px 0px'}>
    //                 <img src="/images/Rectangle 28 (2).svg" alt="Section 2" width="342px" height='100%' className='img-fluid' style={{ marginLeft: '-2px' }} />

    //                 <Box className={classes.boxShadowSubBox}>
    //                   <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} >
    //                     Short-term investing: Options to park ....
    //                   </Typography>
    //                   <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} >
    //                     Fixed deposits serve as an attractive option for short-term investments....
    //                   </Typography>
    //                 </Box>
    //               </Box>
    //             </Box>
    //           </Grid>
    //         </Grid>
    //       </Box>
    //       {/* <Box style={{ height: '0px', display: 'flex', justifyContent: 'start',  }}>
    //           <img src="/images/floatingFiveTwo.svg" alt="Section 2" width="153.43px" height='100px' className='img-fluid' style={{ position: 'relative', bottom: '60px', right: '165px' }} />
    //         </Box> */}
    //       {/* </Container> */}
    //     </div>
    //   );

    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const cardVariants = {
        hidden: { opacity: 0, y: 40 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
    };

    return (
        <div height="100%">
            <motion.div
                ref={sectionRef}
                initial="hidden"
                animate={sectionInView ? "visible" : "hidden"}
                variants={{
                    visible: { transition: { staggerChildren: 0.2 } },
                    hidden: {},
                }}
                className={classes.root}
            >
                <Box margin={'31px 27px'} width={'100%'}>
                    <Grid container spacing={0} alignItems="center" justifyContent="between">
                        {[
                            {
                                img: "/images/Rectangle 28.svg",
                                title: "How mutual funds as low-cost products help",
                                desc: "In the realm of personal finance, dreams often seem distant and unattainable, particularly...."
                            },
                            {
                                img: "/images/Rectangle 28 (1).svg",
                                title: "Spread your risks across sector funds",
                                desc: "As the benchmark equity indices touch all-time highs, investors are looking for value in.."
                            },
                            {
                                img: "/images/Rectangle 28 (2).svg",
                                title: "Short-term investing: Options to park ....",
                                desc: "Fixed deposits serve as an attractive option for short-term investments...."
                            }
                        ].map((card, index) => (
                            <Grid key={index} item xs={12} md={4} style={{ paddingTop: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <motion.div
                                    variants={cardVariants}
                                // initial="hidden"
                                // whileInView="visible"
                                // viewport={{ once: true, amount: 0.3 }}
                                >
                                    <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} maxWidth={"340px"} overflow={'hidden'}>
                                        <img src={card.img} alt="Section Card" width="342px" style={{ marginLeft: '-2px' }} />
                                        <Box className={classes.boxShadowSubBox}>
                                            <Typography variant="h4" className={classes.boxShadowSubHeadingOne}>
                                                {card.title}
                                            </Typography>
                                            <Typography variant="h4" className={classes.boxShadowSubBoxStartData}>
                                                {card.desc}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </motion.div>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </motion.div>
        </div>
    );
}


export default LandingTwelvethSection;


