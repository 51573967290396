import React, { useEffect, useState } from "react";
import { Container, Typography, Grid, makeStyles, Button } from "@material-ui/core";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function LandingNinthSection() {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            background: "#EAF6FF",
            padding: "80px 0",
        },
        heading: {
            fontSize: "32px",
            fontWeight: 700,
            color: "#000",
            marginBottom: "30px",
        },
        subText: {
            fontSize: "20px",
            fontWeight: 500,
            marginBottom: "15px",
            color: "#000000",
        },
        descriptionHead: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "1.7",
            marginBottom: "10px",
            color: "#000000",
        },
        description: {
            fontSize: "16px",
            color: "#757575",
            lineHeight: "1.7",
            marginBottom: "10px",
        },
        linkText: {
            fontSize: "16px",
            color: "#0E76BC",
            textDecoration: "underline",
            cursor: "pointer",
            marginTop: "15px",
            display: "inline-block",
        },
        image: {
            width: "100%",
            height: "auto",
            // borderRadius: "10px",
            objectFit: "cover",
        },
        mainBox: {
            background: '#0000000A',
            height: '470px',
            // maxHeight: '500px',
            overflow: 'hidden',
            borderRadius: "10px",
        },
        "@media (max-width: 1024px)": {

        },
        "@media (max-width: 768px)": {
            mainBox: {
                background: '#0000000A',
                height: 'auto',
                // maxHeight: '500px',
                // overflow: 'hidden',
                // borderRadius: "10px",
            },
        },
        "@media (max-width: 480px)": {

        }

    }));

    const classes = useStyles();

    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const itemVariants = {
        hidden: { opacity: 0, y: 40 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div
            ref={sectionRef}
            initial="hidden"
            animate={sectionInView ? "visible" : "hidden"}
            variants={{
                visible: { transition: { staggerChildren: 0.2 } },
                hidden: {},
            }}
            className={classes.root}
        >
            <Container maxWidth="lg">
                <Typography className={classes.heading}>
                    Smart Investing Made Simple
                </Typography>

                <Grid container spacing={4} alignItems="center" className={classes.mainBox}>
                    <Grid item xs={12} md={6} >
                        <motion.div variants={itemVariants} style={{margin: 'auto 0'}}>
                            <Typography className={classes.subText}>
                                Start a SIP today and let compounding work its magic for your future.
                            </Typography>
                             <Typography className={classes.descriptionHead}>
                                Why is SIP the most popular investment option among Indians?
                            </Typography>
                            <Typography className={classes.description}>
                                SIPs are perfect for beginners and seasoned investors alike, as they promote financial discipline, require minimal effort, and help you achieve long-term goals like retirement, buying a home, or funding education.
                            </Typography>
                            <Typography className={classes.description}>
                                With SIPs, you can start small, stay consistent, and let compounding grow your wealth over time.
                            </Typography>
                            <Typography className={classes.linkText}>
                                Learn more about SIP and why you should invest
                            </Typography>
                        </motion.div>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: 0 }}>
                        <motion.div variants={itemVariants}>
                            <img
                                src="/images/Intro_Page/start Investment Image.svg" // Replace this with your actual image path
                                alt="SIP discussion"
                                className={classes.image}
                            />
                        </motion.div>
                    </Grid>
                </Grid>
            </Container>
        </motion.div>
    );
}

export default LandingNinthSection;
