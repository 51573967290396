import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider, capitalize, CircularProgress, colors } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { GET_PORTFOLIO_FUND_CHANGEDDATA_DISTRIBUTE, GET_PORTFOLIO_FUND_DISTRIBUTE, GET_USER_RISK_PROFILE, KYC_STATUS } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace, BASE_URL } from "../../environment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import AmountModal from "./modal/AmountModal";
import ChangeAmountModal from "./modal/ChangeAmountModal";
import SipGoalsFundForm from "./sipGoalsFund/SipGoalsFundForm";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import LumsumGoalsFundForm from "./lumpsumGoalsFund/LumsumGoalsFundForm";
import CustomToast from "../../components/UI/CustomToast";


const useStyles = makeStyles((theme) => ({

    root: {
        marginBottom: '3rem',
        // padding: "10px",
        borderRadius: "12px",
        // boxShadow: "0 0 8px -3px #444444",
        border: '1px solid #E4E4E4',
        // boxShadow: '0 0 8px -3px #444444',
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        padding: "1rem",
        '& input[type=number]': {
            '-moz-appearance': 'textfield', /* Firefox */
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                display: 'none',
            },
            '-webkit-appearance': 'none', /* Chrome, Safari, Opera */
            '&[type=number]': {
                '-moz-appearance': 'textfield', /* Firefox */
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&[type=number]': {
                '-moz-appearance': 'textfield', /* Firefox */
            },
        },


    },
    tabsFolder: {
        // border: '1px solid #0E76BC',
        // borderRadius: '10px',
        // overflow: 'hidden',
        // padding: `5px`
        maxWidth: '700px',
        width: '100%',
    },
    tabs: {
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #0E76BC',
        borderRadius: '12px',

    },
    tablink: {
        borderRadius: '10px',
        flex: 1,
        padding: '10px 20px',
        borderRadius: 0,
        '&:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
        position: 'relative',
        zIndex: 1,
        color: '#0E76BC',
        textTransform: 'capitalize',
    },
    active: {
        borderRadius: '10px',
        backgroundColor: '#0E76BC',
        color: theme.palette.secondary.contrastText,
    },
    activeBox: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '50%',
        height: '100%',
        // backgroundColor: theme.palette.secondary.main,
        backgroundColor: '#0E76BC',
        borderRadius: '10px',

        transition: 'left 0.3s ease-in-out',
    },
    text1: {
        fontSize: '1rem',
        fontWeight: 600,

    },
    text2: {
        fontSize: '0.8rem',
        color: "#555555"

    },
    recommendedFoundAreaTopHead: {

    },
    recommendedFoundAreaTopHeadText: {
        fontSize: '0.6rem',
        border: '1px solid #E4E4E4',
        backgroundColor: "#E4E4E4",
        padding: '0.2rem 0.3rem',
        borderRadius: "5px 5px 0px 0px",
        textTransform: "capitalize !important",
    },
    recommendedFoundArea: {
        border: '1px solid #E4E4E4',
        padding: '0.5rem',
        borderRadius: "10px",
    },
    rupees: {
        fontFamily: "Open Sans",
    },
    editDeleteIcon: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        cursor: 'pointer',
        fontSize: '0.8rem',
    },
    editFundDataText: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        cursor: 'pointer',
        fontSize: '0.8rem',
        color: "#0E76BC"
    },
    primaryButton: {
        marginTop: '10px',
        padding: "0.3rem 2rem",
        background: "#0E76BC",
        color: "#fff",
        fontSize: "0.8rem",
        fontWeight: 600,
        borderRadius: "45px",
        border: "none",
        cursor: "pointer",
        fontFamily: "inherit",
        transition: '0.2s ease',
        border: "1px solid #0E76BC",
        textAlign: 'center',
        "&:hover": {
            background: "#fff",
            color: "#0E76BC",
        }
    },
}));

const ShowRecommendedFundsOnly = () => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState('basedOnRecommended');
    const [activeBoxPosition, setActiveBoxPosition] = useState(50);
    const [showTheTwoRecommends, setShowTheTwoRecommends] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [transactionBasketItems, setTransactionBasketItems] = useState(null)
    const [fundDetails, setFundDetails] = useState(null)
    const [selectedFund, setSelectedFund] = useState('')
    const { userStore } = useSelector((state) => state);

    const tabClickHandler = (tabName, position) => {
        setActiveTab(tabName);
        setActiveBoxPosition(position);
    };

    // const tabHoverHandler = (tabName, position) => {
    //     // if (!activeTab === tabName && !activeBoxPosition === position){

    //     // }
    // }

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = useLocation()
    const [showContact, setShowContact] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showChangeFungAmount, setChangeFundAmount] = useState(false);
    const [clickContinue, setClickContinue] = useState(false)
    const [riskProfileData, setRiskProfileData] = useState(null)


    // console.log(userStore)

    useEffect(() => {
        if (state?.goalData?.name === "My First Crore" || state?.goalData?.name === "Long term wealth" || state?.goalData?.name === "Retirement") {
            tabClickHandler('basedOnRisk', 0)
            setShowTheTwoRecommends(true)
        }

    }, [state])

    // calculate my first corre post request 
    const RecommendedFunds = async (data) => {
        setLoading(true)
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                model_portfolio_id: data?.goalData?.model_portfolio_id,
                duration: data.data.time,
                amount: data.data.value,
                is_onetime: false
            },
            // data: formData
        };

        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                modal_portfilio_id: 1,
                duration: 8,
                amount: 39000,
                is_onetime: false
            },
            // data: formData1
        };

        try {
            const [responseReturn1, responseReturn2] = await Promise.all([
                httpInstance(`${GET_PORTFOLIO_FUND_DISTRIBUTE}`, httpConfig),
                // httpInstance(`${GET_PORTFOLIO_FUND_DISTRIBUTE}`, httpConfig1),
            ])
            // console.log(responseReturn1, responseReturn2)
            // console.log(responseReturn1?.data?.data?.basket?.transaction_basket_items)
            setTransactionBasketItems(responseReturn1?.data?.data?.basket?.transaction_basket_items)
            setFundDetails(responseReturn1?.data?.data?.fund_details)

            // const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1);
            // console.log(response?.data, 'fadfadfadsfasdfasdfasdf')
            setLoading(false)


        } catch (error) {
            console.log(error.response.data?.error)
            setErrorMessage(error.response.data?.error)
            setLoading(false)

        } finally {
            // setLoading(false)

        }
    }

    //As the slider change in the area 

    useEffect(() => {
        if (state) {
            RecommendedFunds(state);
            get_kycData();
            handleGetRiskProfile()
        }

    }, [state])

    //  delete the fundlist 

    const handleDelete = (fund, items, index) => {
        // console.log(fundDetails, transactionBasketItems)
        const newFundDetails = { ...fundDetails };
        delete newFundDetails[items.fund_isin];
        // Remove from transactionBasketItems 
        const newTransactionBasketItems = transactionBasketItems.filter((data) => data.fund_isin !== items.fund_isin);
        // Update state 
        setFundDetails(newFundDetails);
        setTransactionBasketItems(newTransactionBasketItems);
    }


    // change fund data api call
    const handleFundChange = async (fund, items) => {
        setLoading(true)
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                modal_portfilio_id: 1,
                duration: fund.data.time,
                amount: fund.data.value,
                is_onetime: false
            },
            // data: formData
        };


        try {
            const responseReturn = await httpInstance(`${GET_PORTFOLIO_FUND_CHANGEDDATA_DISTRIBUTE}`, httpConfig);

            // console.log(responseReturn)

            // const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1);
            // console.log(response?.data, 'fadfadfadsfasdfasdfasdf')
            setLoading(false)


        } catch (error) {
            console.log(error.response.data?.error)
            setErrorMessage(error.response.data?.error)
        } finally {
            setLoading(false)

        }
    }



    const open_contact = (fund, items) => {
        setSelectedFund({ fund: fund, items: items })
        setShowContact(true);
    }
    const close_contact = (fundData) => {
        setShowContact(false);
        setSelectedFund('')
        if (fundData) {
            const newBasketItem = fundData?.data?.data?.basket_item; // Single basket item
            const newFundDetails = fundData?.data?.data?.fund_details; // Fund details object
            const newIsin = newBasketItem?.fund_isin; // Extract ISIN from the new basket item
            // console.log(newIsin, newBasketItem)

            if (newIsin) {
                // Update transactionBasketItems by replacing the matching fund
                setTransactionBasketItems((prevBasketItems) =>
                    prevBasketItems.map((item) =>
                        item.fund_isin === newIsin
                            ? { ...item, ...newBasketItem } // Replace only the matching one
                            : item
                    )
                );
                // setFundDetails(fundData?.data?.data?.fund_details)
            }
        }
    }

    const open_success = () => setShowSuccess(true);



    const open_change_fund_amount = (fund, items) => {
        setChangeFundAmount(true);
    }

    const close_change_fund_amount = (fund, more) => {
        setChangeFundAmount(false);
        if (fund) {
            // console.log(fund?.data?.data?.basket_item);

            // Update transactionBasketItems
            setTransactionBasketItems((prevItems) => [
                ...prevItems,
                ...[fund?.data?.data?.basket_item]
            ]);

            // Update fundDetails
            setFundDetails((prevDetails) => ({
                ...prevDetails,
                ...fund?.data?.data?.fund_details
            }));
        }
    };



    const [kycStatus, setKycStatus] = useState();
    const [kycData, setKycData] = useState(null)

    const get_kycData = async () => {
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
        };
        try {
            const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
            setKycData(response?.data?.data)
            setKycStatus(response.data?.data?.fp_kyc_status);
        } catch {
            setKycStatus("empty");
        }
    };

    const [sipGoalsOTPPart, setSipGoalsOTPPart] = useState(0)

    const handleStepOTP = (data) => {
        setSipGoalsOTPPart(data)
    }


    const handleGetRiskProfile = async () => {
        setLoading(true)
        // setError("")
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            // params: { userId: localStorage.getItem("user_id") }
        };

        try {
            const response = await httpInstance(`${GET_USER_RISK_PROFILE}`, httpConfig)
            setRiskProfileData(response?.data?.data)
            // window.open(`${BASE_URL}${response.data?.data}`, '_blank', 'noopener,noreferrer');
            // setLoading(false)
        }
        catch (error) {
            CustomToast(error.response.data?.message ?? error.response.data?.message ?? "Somthing went wrong", 'FAILED')
            // setError(error.response.data?.message)
        }
    }


    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-start"
                px={{ xs: 2, md: 15 }}
                mt={5}
                className={classes.backButton}
            >
                {clickContinue ?
                    <div onClick={() => {
                        if (sipGoalsOTPPart === 1) handleStepOTP(0)
                        else if (sipGoalsOTPPart === 2) handleStepOTP(1)
                        else if (sipGoalsOTPPart === 3) handleStepOTP(1)
                        else setClickContinue(false)

                    }} style={{ cursor: 'pointer' }}>
                        <img src="/images/backIcon.svg" height="auto" width="20px" />

                    </div>
                    :
                    <div onClick={() => {
                        navigate(`${namespace}/goals/type/${state?.goalData?.name}`, {
                            state: { data: state?.goalData },
                        });
                    }} style={{ cursor: 'pointer' }}>
                        <img src="/images/backIcon.svg" height="auto" width="20px" />

                    </div>
                }

            </Box >
            <Box
                // display="flex"
                px={{ xs: 1, md: 15 }}
                pt={2}
            // style={{ borderBottom: "1px solid #E6E3E3" }}
            >
                <div className={classes.root}>



                    {clickContinue ? <>

                        {state?.goalData?.name === "Principal Protection" ?

                            <LumsumGoalsFundForm
                                fundData={fundDetails}
                                // fpData={fpData}
                                kycStatus={kycStatus}
                                // show_banner={show_banner}
                                kycData={kycData}
                                fundTransactionBasket={transactionBasketItems}
                                state={state}
                            />

                            :
                            <SipGoalsFundForm
                                fundData={fundDetails}
                                // fpData={fpData}
                                kycStatus={kycStatus}
                                // show_banner={show_banner}
                                kycData={kycData}
                                fundTransactionBasket={transactionBasketItems}
                                handleStepOTP={handleStepOTP}
                                handleStepsOTP={sipGoalsOTPPart}
                            />
                        }
                    </>
                        :
                        <div>
                            <Typography variant="body" className={classes.text1}>Recommended funds are </Typography>
                            {showTheTwoRecommends &&
                                <Box display={'flex'} justifyContent={'center'} width={'100%'} my={3}>
                                    <div className={classes.tabsFolder} >
                                        <div className={classes.tabs}>
                                            <div className={classes.activeBox} style={{ left: `${activeBoxPosition}%` }}></div>
                                            <Button
                                                className={`${classes.tablink} ${activeTab === 'basedOnRisk' ? classes.active : ''}`}
                                                onClick={() => tabClickHandler('basedOnRisk', 0)}
                                            // onMouseEnter={() => tabHoverHandler('basedOnRisk', 0)}
                                            >
                                                Based on Risk Profile
                                            </Button>
                                            <Button
                                                className={`${classes.tablink} ${activeTab === 'basedOnRecommended' ? classes.active : ''}`}
                                                onClick={() => tabClickHandler('basedOnRecommended', 50)}>
                                                MF Wealth Recommended
                                            </Button>
                                        </div>
                                    </div>
                                </Box>
                            }
                            {activeTab === 'basedOnRisk' &&
                                <>
                                    {errorMessage ? (
                                        <ErrorMessage message={errorMessage} />
                                    ) : (
                                        <>

                                            <Box>
                                                <Typography className={classes.text2}>This fund is designed to offer  both higher liquidity and increased safety  </Typography>
                                                <Box id="basedOnRisk" className={activeTab === 'basedOnRisk' ? 'tabcontent active' : 'tabcontent'} mt={3}>
                                                    {/* <h3>Tab 1 Content</h3>
                                            <p>This is the content of Tab 1.</p> */}
                                                    {loading ?


                                                        <Box >
                                                            <Box ml={2} className={classes.recommendedFoundAreaTopHead}>
                                                                <Skeleton variant="text" width={'100px'} />
                                                            </Box>
                                                            <Box className={classes.recommendedFoundArea}>

                                                                {/* {items.fund_isin} */}
                                                                <Box className={classes.recommendedFoundAreaLeft}
                                                                    display={'flex'} alignItems={"center"}
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Box display={'flex'} alignItems={"center"} width={'100%'}>

                                                                        <Skeleton variant="rounded" border width={50} height={50} />
                                                                        <Skeleton variant="text" width={'60%'} />

                                                                    </Box>

                                                                    <Skeleton variant="text" width={'100px'} />


                                                                </Box>
                                                                <Box display={'flex'} alignItems={"center"}
                                                                    justifyContent="end">
                                                                    <Skeleton variant="text" width={'100px'} />


                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        :
                                                        !riskProfileData?.risk_profile ? (
                                                            <Box textAlign='center'>
                                                                <Typography className={classes.text2}>Please Complete your risk assessment </Typography>
                                                                <Box display={'flex'} justifyContent='center'>

                                                                    <button className={classes.primaryButton} style={{ padding: '10px 25px' }} type="button" onClick={() => navigate(`${namespace}/my-profile/riskProfile`)}>
                                                                        Complete Risk Profile
                                                                    </button>
                                                                </Box>
                                                            </Box>
                                                        ) : (
                                                            fundDetails && transactionBasketItems.length > 0 ?
                                                                <>
                                                                    {transactionBasketItems?.map((items, index) => {
                                                                        return (
                                                                            <Box key={index}>
                                                                                <Box ml={2} className={classes.recommendedFoundAreaTopHead}>
                                                                                    <Typography variant="body" className={classes.recommendedFoundAreaTopHeadText}>{fundDetails[items.fund_isin]?.fund_category}: {fundDetails[items.fund_isin]?.plan_type}</Typography>
                                                                                </Box>
                                                                                <Box className={classes.recommendedFoundArea}>

                                                                                    {/* {items.fund_isin} */}
                                                                                    <Box className={classes.recommendedFoundAreaLeft}
                                                                                        display={'flex'} alignItems={"center"}
                                                                                        justifyContent="space-between"
                                                                                    >
                                                                                        <Box display={'flex'} alignItems={"center"}>
                                                                                            <img src={fundDetails[items.fund_isin]?.scheme_logo} height='40px' width="40px" alt="logo" style={{ marginRight: "10px" }} />
                                                                                            <Typography variant="body" >

                                                                                                {fundDetails[items.fund_isin]?.name}
                                                                                            </Typography>
                                                                                        </Box>

                                                                                        {/* {fundDetails[items.fund_isin]?.name} */}
                                                                                        <Typography variant="body" className={classes.text1}>
                                                                                            <span className={classes.rupees}>₹</span>
                                                                                            {items.amount?.toLocaleString('en-IN')}
                                                                                        </Typography>

                                                                                    </Box>
                                                                                    <Box display={'flex'} alignItems={"center"}
                                                                                        justifyContent="end">
                                                                                        {/* {console.log(state?.goalData)} */}
                                                                                        {!(state?.goalData?.name === "Principal Protection") &&
                                                                                            <Typography variant="body" className={classes.editDeleteIcon} onClick={
                                                                                                () => open_contact(fundDetails[items.fund_isin], items)
                                                                                            }>
                                                                                                <img src='/images/editIcon.svg' height={15} width={15} alt="" />
                                                                                                Edit
                                                                                            </Typography>
                                                                                        }
                                                                                        <Box className={classes.editDeleteIcon} onClick={() => handleDelete(fundDetails[items.fund_isin], items, index)}>
                                                                                            <img src='/images/deleteIcon.svg' height={15} width={15} alt="" />
                                                                                            Delete
                                                                                        </Box>
                                                                                    </Box>
                                                                                    {/* Your content here */}
                                                                                </Box>
                                                                            </Box>

                                                                        );
                                                                    })}
                                                                    <Box display={'flex'} justifyContent='center' mt={2}>
                                                                        <Typography variant="body" className={classes.editFundDataText} onClick={
                                                                            () => open_change_fund_amount()
                                                                        }>
                                                                            {/* <img src='/images/editIcon.svg' height={15} width={15} alt="" /> */}
                                                                            I want to add change the fund
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box display={'flex'} justifyContent='center'>
                                                                        <button className={classes.primaryButton} type="button" onClick={() => setClickContinue(true)}>
                                                                            {loading ? (
                                                                                <CircularProgress size="1rem" color="inherit" />
                                                                            ) : (
                                                                                "Continue"
                                                                            )}
                                                                        </button>
                                                                    </Box>
                                                                </>
                                                                :
                                                                <Typography className={classes.text2}>No recomendation  </Typography>
                                                        )}
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                </>
                            }

                            {activeTab === 'basedOnRecommended' &&
                                <>
                                    {errorMessage ? (
                                        <ErrorMessage message={errorMessage} />
                                    ) : (
                                        <Box>
                                            <Typography className={classes.text2}>This fund is designed to offer  both higher liquidity and increased safety  </Typography>
                                            <Box id="basedOnRisk" className={activeTab === 'basedOnRisk' ? 'tabcontent active' : 'tabcontent'} mt={3}>
                                                {/* <h3>Tab 1 Content</h3>
                                            <p>This is the content of Tab 1.</p> */}
                                                {loading ?


                                                    <Box >
                                                        <Box ml={2} className={classes.recommendedFoundAreaTopHead}>
                                                            <Skeleton variant="text" width={'100px'} />
                                                        </Box>
                                                        <Box className={classes.recommendedFoundArea}>

                                                            {/* {items.fund_isin} */}
                                                            <Box className={classes.recommendedFoundAreaLeft}
                                                                display={'flex'} alignItems={"center"}
                                                                justifyContent="space-between"
                                                            >
                                                                <Box display={'flex'} alignItems={"center"} width={'100%'}>

                                                                    <Skeleton variant="rounded" border width={50} height={50} />
                                                                    <Skeleton variant="text" width={'60%'} />

                                                                </Box>

                                                                <Skeleton variant="text" width={'100px'} />


                                                            </Box>
                                                            <Box display={'flex'} alignItems={"center"}
                                                                justifyContent="end">
                                                                <Skeleton variant="text" width={'100px'} />


                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    fundDetails && transactionBasketItems.length > 0 ?
                                                        <>
                                                            {transactionBasketItems?.map((items, index) => {
                                                                return (
                                                                    <Box key={index}>
                                                                        <Box ml={2} className={classes.recommendedFoundAreaTopHead}>
                                                                            <Typography variant="body" className={classes.recommendedFoundAreaTopHeadText}>{fundDetails[items.fund_isin]?.fund_category}: {fundDetails[items.fund_isin]?.plan_type}</Typography>
                                                                        </Box>
                                                                        <Box className={classes.recommendedFoundArea}>

                                                                            {/* {items.fund_isin} */}
                                                                            <Box className={classes.recommendedFoundAreaLeft}
                                                                                display={'flex'} alignItems={"center"}
                                                                                justifyContent="space-between"
                                                                            >
                                                                                <Box display={'flex'} alignItems={"center"}>
                                                                                    <img src={fundDetails[items.fund_isin]?.scheme_logo} height='40px' width="40px" alt="logo" style={{ marginRight: "10px" }} />
                                                                                    <Typography variant="body" >

                                                                                        {fundDetails[items.fund_isin]?.name}
                                                                                    </Typography>
                                                                                </Box>

                                                                                {/* {fundDetails[items.fund_isin]?.name} */}
                                                                                <Typography variant="body" className={classes.text1}>
                                                                                    <span className={classes.rupees}>₹</span>
                                                                                    {items.amount?.toLocaleString('en-IN')}
                                                                                </Typography>

                                                                            </Box>
                                                                            <Box display={'flex'} alignItems={"center"}
                                                                                justifyContent="end">
                                                                                {/* {console.log(state?.goalData)} */}
                                                                                {!(state?.goalData?.name === "Principal Protection") &&
                                                                                    <Typography variant="body" className={classes.editDeleteIcon} onClick={
                                                                                        () => open_contact(fundDetails[items.fund_isin], items)
                                                                                    }>
                                                                                        <img src='/images/editIcon.svg' height={15} width={15} alt="" />
                                                                                        Edit
                                                                                    </Typography>
                                                                                }
                                                                                <Box className={classes.editDeleteIcon} onClick={() => handleDelete(fundDetails[items.fund_isin], items, index)}>
                                                                                    <img src='/images/deleteIcon.svg' height={15} width={15} alt="" />
                                                                                    Delete
                                                                                </Box>
                                                                            </Box>
                                                                            {/* Your content here */}
                                                                        </Box>
                                                                    </Box>

                                                                );
                                                            })}
                                                            <Box display={'flex'} justifyContent='center' mt={2}>
                                                                <Typography variant="body" className={classes.editFundDataText} onClick={
                                                                    () => open_change_fund_amount()
                                                                }>
                                                                    {/* <img src='/images/editIcon.svg' height={15} width={15} alt="" /> */}
                                                                    I want to add change the fund
                                                                </Typography>
                                                            </Box>
                                                            <Box display={'flex'} justifyContent='center'>
                                                                <button className={classes.primaryButton} type="button" onClick={() => setClickContinue(true)}>
                                                                    {loading ? (
                                                                        <CircularProgress size="1rem" color="inherit" />
                                                                    ) : (
                                                                        "Continue"
                                                                    )}
                                                                </button>
                                                            </Box>
                                                        </>
                                                        :
                                                        <Typography className={classes.text2}>No recomendation  </Typography>
                                                }
                                            </Box>

                                        </Box>
                                    )}
                                </>

                            }


                        </div>
                    }
                    <AmountModal
                        selectedFund={selectedFund}
                        showModal={showContact}
                        handleCloseModal={close_contact}
                        handleShowSuccess={open_success}
                    />
                    <ChangeAmountModal
                        selectedFund={selectedFund}
                        showModal={showChangeFungAmount}
                        handleCloseModal={close_change_fund_amount}
                        handleShowSuccess={open_success}
                    />
                </div >
            </Box>
        </>
    );
};


export default ShowRecommendedFundsOnly;
