import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, Box, Typography, Grid, Paper } from "@material-ui/core";
import httpInstance from "../../../utils/instance";
import { SEARCH_FUNDS, GET_ALL_AMC, SEARCH_FUNDS_BY_NAME, GET_ALL_SPECIFIC_AMC_LIST, GET_FUND_BASKET } from "../../../utils/endpoints";
import FundSkeleton from "../../../containers/Dashboard/Funds/CommonLoader/FundSkeleton";
import CustomToast from "../../UI/CustomToast";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { namespace } from "../../../environment";


const useStyles = makeStyles((theme) => ({
    searchBar: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        margin: "28px 0px 20px 0px",
        borderRadius: "10px",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: 100,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
    },
    SearchBarMargin: {
        marginTop: "0rem",
        // marginLeft: "2rem",
    },
    text: {
        marginTop: '20px',
        fontSize: '.8rem',
        color: 'grey',
        textAlign: "center",
    },
    boxDesign: {
        border: "1px solid rgb(212, 212, 212)",
        borderRadius: "10px",
        // maxWidth: "200px",
        width: "100%",
        // margin: "10px 0px",
        height: "70px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexDirection: 'column',
        cursor: "pointer",
        "&:hover": {
            // boxShadow: "0px 0px 10px 0px rgb(97, 97, 97)",
            boxShadow: '0px 0px 5px 3px rgba(12,151,240,0.28)',
            border: "1px solid #0C97F0",
            // boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px'
        }
    },

    fundName: {
        fontSize: "1rem",
        padding: "10px",
        fontWeight: 600,
        textAlign: "center",
    },
    boxMain: {
        padding: "0.5rem 0.5rem",
    },
    headerFont: {
        fontSize: "1.1rem",
        fontWeight: "500",
    },
    "@media (max-width: 600px)": {
        SearchBarMargin: {
            marginLeft: "0rem",
        },
    },
}));



const FundBasketData = () => {
    const classes = useStyles();
    const [allFunds, setAllFunds] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();


    const get_allFunds = async () => {

        const httpConfig = {
            method: "GET",
            tokenType: "user",
            // test: 'test',
            headers: {
                "Content-Type": "application/json",
            },
            // data: { ...formData, ...filterData },
            // data: { ...formData },
            params: { category_name: 'Fund Basket' }


        };
        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            // test: 'test',
            headers: {
                "Content-Type": "application/json",
            },
        };
        // console.log(httpConfig)

        try {
            const response = await httpInstance(`${GET_FUND_BASKET}`, httpConfig);
            // const responsenext = await httpInstance (`${GET_ALL_AMC}`)
            // console.log(response.data.data)
            setAllFunds([...allFunds, ...response.data?.data]);
            setHasMore(false);

            //   if (response.data?.responseCount == 1) {
            //     setHasMore(false);
            //   } else {
            //     setAllFunds([...allFunds, ...response.data?.data]);
            //     set_page();
            //   }
        } catch (error) {
            CustomToast(error.response.data.message ?? error.response.data.error ?? "Something went wrong", 'FAILED')
        }
    };


    useEffect(() => {
        get_allFunds();
    }, {});



    const fetchData = () => {
        get_allFunds();
       
    };



    const LoaderComp = () => {
        return (
            <>
                <Grid container spacing={2} justifyContent="center" className={classes.boxMain}>

                    {[1, 2, 3, 4, 5]?.map((fund, i) => {
                        return (
                            <>
                                <Grid item xs={12} sm={6} md={3} key={i} >
                                    <Skeleton variant="rounded" width={'100%'} height={70} style={{ marginRight: '10px' }} />
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </>
        );
    };

    const handleFundBusket = (fund) => {
        navigate(
            `${namespace}/mutualfund/fund-basket/fund`,
            {
                state: {
                    fundData: fund,
                }
            }
        );
    }


    return (
        <div>
            <Box px={{ xs: 2, md: 14 }}>
                <Box mt={4} mb={2}>
                    <Typography variant="h5" style={{ textAlign: "start" }} className={classes.headerFont}>Fund Basket</Typography>
                </Box>
                <div >
                    <InfiniteScroll
                        style={{
                            overflow: "hidden"
                        }}
                        dataLength={allFunds?.length}
                        next={fetchData}
                        hasMore={hasMore}
                        loader={<LoaderComp />}
                        endMessage={
                            <Typography className={classes.text}>
                                {/* No More Funds to display */}
                            </Typography>
                        }
                    >
                        <Grid container spacing={2} justifyContent="center" className={classes.boxMain}>

                            {allFunds?.map((fund, i) => {
                                return (
                                    <>
                                        <Grid item xs={12} sm={6} md={3} key={i}>
                                            <Paper className={classes.boxDesign} onClick={()=>handleFundBusket(fund)}>
                                                <img src={fund.icon} alt="icon" height={'50px'} width={'50px'}/>
                                                <Typography variant="h6" className={classes.fundName}>{fund?.name}</Typography>
                                            </Paper>
                                        </Grid>


                                    </>
                                )
                            })}
                        </Grid>
                    </InfiniteScroll>
                </div>
                {/* {allFunds?.map((fund)=>{
                 return(
                   <CardData
                     key={fund.scheme_code}
                     fund={fund}
                   />
                 )
               })} */}
            </Box>
        </div >
    )
}
export default FundBasketData;