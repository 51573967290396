import React, { useState } from "react";
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f9f9f9",
    padding: "80px 0",
  },
  heading: {
    fontSize: "32px",
    fontWeight: "700",
    textAlign: "center",
    marginBottom: "40px",
  },
  accordion: {
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #e0e0e0",
    boxShadow: "none",
  },
  accordionSummary: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#333",
  },
  accordionDetails: {
    color: "#666",
    fontSize: "15px",
  },
}));

const faqData = [
  {
    question: "Why choose MF Wealth over other platforms?",
    answer: "MF Wealth offers personalized investment portfolios, a dedicated relationship manager for all investors, dynamic SIPs, and annual portfolio rebalancing—all with zero hidden fees. Our focus is on tailored solutions, transparency, and expert guidance to help you achieve your financial goals.",
  },
  {
    question: "What is personalized wealth management?",
    answer: "Personalized wealth management at MF Wealth means creating customized investment portfolios based on your unique financial goals, risk appetite, and investment horizon. Our experts curate and manage your portfolio to ensure it aligns with your needs.",
  },
  {
    question: "Why invest in mutual funds?",
    answer: "Mutual funds offer diversification, professional management, and the potential for higher returns compared to traditional investment options. They are ideal for both short-term and long-term financial goals, making them a flexible and accessible investment choice.",
  },
  {
    question: "What license does MF Wealth hold?",
    answer: "MF Wealth operates under the regulatory framework of SEBI (Securities and Exchange Bord of India) and is registered as a Mutual Fund Distributor. We adhere to all compliance and security standards to ensure a safe and trustworthy platform.",
  },
  {
    question: "How much returns can I expect if invested through MF Wealth?",
    answer: "Returns depend on market conditions, your risk profile, and the type of funds you choose. Historically, equity mutual funds have delivered 10-12% annual returns over the long term, while debt funds offer 6-8%. Our experts design portfolios to maximize returns while managing risk.",
  },
  {
    question: "Why should I trust MF Wealth?",
    answer: "MF Wealth is backed by a team of experienced financial experts, uses cutting-edge technology, and operates with complete transparency. We prioritize your financial well-being and provide tailored solutions to help you achieve your goals",
  },
  {
    question: "Is MF Wealth safe to invest with?",
    answer: "Yes, MF Wealth is completely safe. We partner with BSE Star MF, a trusted and secure platform, and follow stringent data security protocols. Your investments are protected, and we ensure complete transparency in all transactions",
  },
  {
    question: "Can I start investing with a small amount?",
    answer: "Absolutely! With MF Wealth, you can start investing in mutual funds with as little as Rs. 500 per month through SIPs. Our platform is designed to make investing accessible and hassle-free for everyone",
  },
];

function LandingFAQSection() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Typography className={classes.heading}>Frequently Asked Questions (FAQs)</Typography>
        {faqData.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography className={classes.accordionSummary}>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.accordionDetails}>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </div>
  );
}

export default LandingFAQSection;
