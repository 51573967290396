
// // DashboardSection.js
// import React, { useEffect, useState } from 'react';
// import { Box, Container, Typography, Grid, Button, makeStyles, capitalize } from "@material-ui/core";
// import AppleIcon from '@mui/icons-material/Apple';
// import AndroidIcon from '@mui/icons-material/Android';
// import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// import CountUp from 'react-countup';


// function LandingSection({ offset, handleShowModal }) {
//     const [refreshToken, setRefreshToken] = useState("");
//     useEffect(() => {
//         setRefreshToken(localStorage.getItem("refresh_token"));
//     }, [localStorage]);
//     // console.log(refreshToken);
//     const useStyles = makeStyles((theme) => ({
//         root: {
//             // backgroundImage: "url('./images/introBG.svg')",
//             // backgroundColor: "#F7F7F9",
//             padding: "30px 0rem 68px 0rem",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "flex-start",
//             flexDirection: "column",
//             background: 'linear-gradient(90deg, #E9F4FF 18.12%, #F8FAFC 100%)'

//         },
//         headingOne: {
//             fontSize: "40px",
//             fontWeight: 500,
//             color: "#00AEEF",
//             marginBo5tom: "1.2rem",
//             textAlign: "start",
//         },

//         headingOneColor: {
//             color: "#0E76BC",
//         },
//         headingOneParagraph: {
//             fontSize: "32px",
//             fontWeight: 600,
//             color: "#00000",
//             marginBottom: "1.2rem",
//             textAlign: "center",
//         },
//         headingOneParagraphSub: {
//             fontSize: "16px",
//             fontWeight: 400,
//             color: "#00000",
//             marginBottom: "1rem",
//             textAlign: "center",
//             display: 'flex',
//             justifyContent: 'center',
//         },
//         gridLayoutLeft: {
//             display: 'flex',
//             justifyContent: 'end',
//             alignItems: 'center',
//         },
//         gridLayoutRight: {
//             display: 'flex',
//             justifyContent: 'start',
//             alignItems: 'center',
//         },
//         headingTwo: {
//             fontSize: "16px",
//             fontWeight: 400,
//             color: "#F3C04F",
//             textAlign: "start",
//             color: "#000000B8",
//             padding: "0px 50px 20px 0px",
//         },

//         buttonStartDownload:{
//             padding: "15px 13px",
//             width: '100%',
//             maxWidth: '230px',
//             background: "#0E76BC",
//             color: "#fff",
//             borderRadius: "25px",
//             display: 'flex',
//             justifyContent: "space-between",
//             cursor: "pointer",
//             border: "1px solid #828282",
//             textTransform: "capitalize",
//             "&:hover": {
//                 background: "#00AEEF",
//                 border: "1px solid #0E76BC",

//               },
//         },
//         buttonExplore:{
//             padding: "15px 13px",
//             width: '100%',
//             maxWidth: '230px',
//             background: "#fff",
//             color: "#000000",
//             borderRadius: "25px",
//             display: 'flex',
//             justifyContent: "center",
//             cursor: "pointer",
//             border: "1px solid #828282",
//             textTransform: "capitalize",
//             "&:hover": {
//                 background: "#E9F4FF",
//                 border: "1px solid #0E76BC",
//               },
//         },
//         button: {
//             padding: "1rem 4rem",
//             background: "#0E76BC",
//             color: "#fff",
//             fontSize: "1rem",
//             borderRadius: "40px",
//             border: "none",
//             margin: "1.8rem 0",
//             cursor: "pointer",
//         },

//         boxShadow: {
//             background: '#FFFFFF40',
//             boxShadow: ' 0px 4px 4px 0px #00000040',
//             minWidth: '110px',
//             borderRadius: '5px',
//         },
//         boxShadowNumber: {
//             fontFamily: "Poppins",
//             fontSize: '20px',
//             fontWeight: 400,
//             textAlign: 'center',
//             color: "#000000",
//         },
//         boxShadowData: {
//             fontFamily: "Poppins",
//             fontSize: '14px',
//             fontWeight: 700,
//             textAlign: 'center',
//             color: "#0E76BC",
//         },
//         "@media (max-width: 900px)": {
//             root: {
//                 padding: "8rem 0rem 1rem 0rem",
//             },
//             headingOne: {
//                 fontSize: "1.6rem",
//             },
//             headingTwo: {
//                 fontSize: "12px",
//             },
//         },


//     }));
//     const classes = useStyles();

//     return (
//         <div className={classes.root} height="100%">
//             <Container maxWidth="lg">
//                 <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
//                     <Typography variant="h6" className={classes.headingOne}>
//                         <span className={classes.headingOneColor}>MF</span> Wealth
//                     </Typography>
//                     <Typography variant="body2" className={classes.headingOneParagraph}>
//                         Your Wealth - Our Expertise
//                     </Typography>
//                     <Box maxWidth={'600px'} width={'100%'}>
//                         <Typography variant="body2" className={classes.headingOneParagraphSub}>
//                             Expert-curated mutual fund portfolios designed to maximize your returns effortlessly.
//                         </Typography>
//                     </Box>

//                 </Box>
//                 <Box>

//                     <Grid container spacing={4} alignItems="center" >
//                         <Grid item xs={12} md={6} className={classes.gridLayoutLeft}>
//                             <Button className={classes.buttonStartDownload}>
//                                 Get Started 
//                                 <div>
//                                     <img src='/images/Intro_Page/mage_playstore.svg' alt='download' width={'auto'} height={'auto'}/>
//                                     <img src='/images/Intro_Page/mage_playstore.svg' alt='download' width={'auto'} height={'auto'}/>
//                                     <img src='/images/Intro_Page/simple-icons_appstore.svg' alt='download' width={'auto'} height={'auto'}/>

//                                 </div>
//                             </Button>
//                         </Grid>
//                         <Grid item xs={12} md={6} className={classes.gridLayoutRight}>
//                             <Button className={classes.buttonExplore}>
//                                 Explore Mutual Funds
//                             </Button>
//                         </Grid>
//                     </Grid>

//                     <Box mt={2} sx={{ textAlign: 'center' }}
//                     // onClick={() => {
//                     //     window.open('https://apps.apple.com/in/app/mf-wealth/id6738738300', '_blank')
//                     // }}
//                     >
//                         <img src="/images/Intro_Page/mfwealthLandingPage.svg" alt="Dashboard Illustration" width="100%" className='img-fluid' style={{ height: '100%', width: 'auto' }} />
//                     </Box>
//                 </Box>

//             </Container>
//         </div>
//     );
// }

// export default LandingSection;



import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { useInView } from 'react-intersection-observer';  // Import IntersectionObserver hook
import { motion } from 'framer-motion';  // Import framer-motion for animations
import UseStoreLink from './UseStoreLink';
import { namespace } from '../../environment';
import { useNavigate } from 'react-router-dom';

// Your other imports...

function LandingSection({ offset, handleShowModal }) {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, [localStorage]);

    // Your existing styles...
    const useStyles = makeStyles((theme) => ({
        root: {
            padding: "30px 0rem 68px 0rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            background: 'linear-gradient(90deg, #E9F4FF 18.12%, #F8FAFC 100%)',
        },
        headingOne: {
            fontSize: "40px",
            fontWeight: 500,
            color: "#00AEEF",
            marginBottom: "auto",
            textAlign: "start",
        },
        headingOneColor: {
            color: "#0E76BC",
        },
        headingOneParagraph: {
            fontSize: "32px",
            fontWeight: 600,
            color: "#00000",
            marginBottom: "1.2rem",
            textAlign: "center",
        },
        headingOneParagraphSub: {
            fontSize: "16px",
            fontWeight: 400,
            color: "#00000",
            marginBottom: "1rem",
            textAlign: "center",
            display: 'flex',
            justifyContent: 'center',
        },
        buttonStartDownload: {
            padding: "15px 13px",
            width: '100%',
            maxWidth: '230px',
            background: "#0E76BC",
            color: "#fff",
            borderRadius: "25px",
            display: 'flex',
            justifyContent: "space-between",
            alignItems: 'center',
            cursor: "pointer",
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#00AEEF",
                border: "1px solid #0E76BC",
            },
        },
        buttonExplore: {
            padding: "15px 13px",
            width: '100%',
            maxWidth: '230px',
            background: "#fff",
            color: "#000000",
            borderRadius: "25px",
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            cursor: "pointer",
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#E9F4FF",
                border: "1px solid #0E76BC",
            },
        },
        boxShadow: {
            background: '#FFFFFF40',
            boxShadow: '0px 4px 4px 0px #00000040',
            minWidth: '110px',
            borderRadius: '5px',
        },
        boxShadowNumber: {
            fontFamily: "Poppins",
            fontSize: '20px',
            fontWeight: 400,
            textAlign: 'center',
            color: "#000000",
        },
        boxShadowData: {
            fontFamily: "Poppins",
            fontSize: '14px',
            fontWeight: 700,
            textAlign: 'center',
            color: "#0E76BC",
        },
        gridLayoutLeft: {
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
        },
        gridLayoutRight: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
        },
        "@media (max-width: 900px)": {
            root: {
                padding: "8rem 0rem 1rem 0rem",
            },
            headingOne: {
                fontSize: "1.6rem",
            },
            headingTwo: {
                fontSize: "12px",
            },
        },
        "@media (max-width: 1024px)": {
            // root: {
            //     padding: "3rem 0",
            // },
            headingOne: {
                fontSize: "2rem",
            },
            headingOneParagraph: {
                fontSize: "1.3rem",
            },
            headingOneParagraphSub: {
                fontSize: "0.9rem",
            },
            gridLayoutLeft: {
                justifyContent: 'center',
            },
            gridLayoutRight: {
                justifyContent: 'center',
            }
        },
        "@media (max-width: 768px)": {
            headingOne: {
                fontSize: "1.8rem",
            },
            headingOneParagraph: {
                fontSize: "1.2rem",
            },
            headingOneParagraphSub: {
                fontSize: "0.85rem",
            },
            buttonStartDownload: {
                maxWidth: "200px",
            },
            buttonExplore: {
                maxWidth: "200px",
            },
            gridLayoutLeft: {
                justifyContent: 'center',
            },
            gridLayoutRight: {
                justifyContent: 'center',
            }
        },
        "@media (max-width: 480px)": {
            headingOne: {
                fontSize: "1.6rem",
            },
            headingOneParagraph: {
                fontSize: "1rem",
            },
            headingOneParagraphSub: {
                fontSize: "0.8rem",
            },
            buttonStartDownload: {
                maxWidth: "180px",
            },
            buttonExplore: {
                maxWidth: "180px",
            },
            gridLayoutLeft: {
                justifyContent: 'center',
            },
            gridLayoutRight: {
                justifyContent: 'center',
            }
        },
    }));

    const classes = useStyles();
    const storeLink = UseStoreLink(); // Get the store link
    const navigate = useNavigate();


    // Scroll animation using IntersectionObserver
    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,   // Trigger the animation once when it comes into view
        threshold: 0.5,      // Trigger when 50% of the element is visible
    });

    const containerVariants = {
        hidden: { opacity: 1 }, // Keeps the container visible
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.5, // Delay each child element by 0.5 seconds
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, x: 100 }, // Start from right
        visible: { opacity: 1, x: 0 }, // Slide in to center
    };




    return (
        <div className={classes.root} style={{ height: "100%" }}>
            <Container maxWidth="lg">
                {/* <motion.div
                    ref={sectionRef}
                    initial={{ opacity: 0, y: 50 }}  // Start with an invisible and lower position
                    animate={{ opacity: sectionInView ? 1 : 0, y: sectionInView ? 0 : 50 }} // Fade in and move up
                    transition={{ duration: 1 }}  // Animation duration
                > */}
                <motion.div
                    ref={sectionRef}
                    initial="hidden"
                    animate={sectionInView ? "visible" : "hidden"}
                    variants={containerVariants}
                >
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>

                        <motion.div variants={itemVariants}>
                            <Typography variant="h6" className={classes.headingOne}>
                                <span className={classes.headingOneColor}>MF</span> Wealth
                            </Typography>
                        </motion.div>
                        <motion.div variants={itemVariants}>
                            <Typography variant="body2" className={classes.headingOneParagraph}>
                                Your Wealth - Our Expertise
                            </Typography>
                        </motion.div>
                        <motion.div variants={itemVariants}>
                            <Typography variant="body2" className={classes.headingOneParagraphSub}>
                                Expert-curated mutual fund portfolios designed to maximize your returns effortlessly.
                            </Typography>
                        </motion.div>
                    </Box>
                </motion.div>
                {/* <motion.div
                            ref={sectionRef}
                            initial={{ opacity: 0, y: 50 }}  // Start with an invisible and lower position
                            animate={{ opacity: sectionInView ? 1 : 0, y: sectionInView ? 0 : 50 }} // Fade in and move up
                            transition={{ duration: 1 }}  // Animation duration
                        >
                            <Typography variant="h6" className={classes.headingOne}>
                                <span className={classes.headingOneColor}>MF</span> Wealth
                            </Typography>
                        </motion.div>

                        <Typography variant="body2" className={classes.headingOneParagraph}>
                            Your Wealth - Our Expertise
                        </Typography>
                        <Box maxWidth={'600px'} width={'100%'}>
                            <Typography variant="body2" className={classes.headingOneParagraphSub}>
                                Expert-curated mutual fund portfolios designed to maximize your returns effortlessly.
                            </Typography>
                        </Box> */}
                {/* </Box> */}

                {/* </motion.div> */}
                <Box>
                    <Box margin={'auto 0px'}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={6} className={classes.gridLayoutLeft}>
                                <motion.div
                                    initial={{ opacity: 0, y: 70 }}
                                    animate={{ opacity: sectionInView ? 1 : 0, y: sectionInView ? 0 : 50 }}
                                    transition={{ duration: 1 }}
                                    style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                >
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.95 }}
                                        style={{ background: 'none', border: 'none', width: '100%' }}
                                        className={classes.gridLayoutLeft}
                                    >
                                        <Button className={classes.buttonStartDownload}
                                            onClick={() => window.open(storeLink, "_blank")} // Open in a new tab
                                        >
                                            Get Started
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} marginLeft={2}>
                                                <img src='/images/Intro_Page/mage_playstore.svg' alt='download' width={'auto'} height={'18px'} />
                                                <img src='/images/Intro_Page/paystore_appstore_split.svg' alt='download' width={'auto'} height={'18px'} style={{ margin: "0px 4px" }} />
                                                <img src='/images/Intro_Page/simple-icons_appstore.svg' alt='download' width={'auto'} height={'18px'} />
                                            </Box>
                                        </Button>
                                    </motion.button>
                                </motion.div>
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.gridLayoutRight}>
                                <motion.div
                                    initial={{ opacity: 0, y: 70 }}
                                    animate={{ opacity: sectionInView ? 1 : 0, y: sectionInView ? 0 : 50 }}
                                    transition={{ duration: 1 }}
                                    style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                >
                                    <motion.button
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.95 }}
                                        style={{ background: 'none', border: 'none', width: '100%' }}
                                        className={classes.gridLayoutRight}

                                    >
                                        <Button className={classes.buttonExplore}
                                            onClick={() => {
                                                navigate(`${namespace}/user`);
                                            }}>
                                            Explore Mutual Funds
                                        </Button>
                                    </motion.button>
                                </motion.div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2} sx={{ textAlign: 'center', mt: 3 }} >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: sectionInView ? 1 : 0 }}
                            transition={{ duration: 1 }}
                        >
                            <img
                                src="/images/Intro_Page/mfwealthLandingPage.svg"
                                alt="Dashboard Illustration"
                                width="100%"
                                className='img-fluid'
                                style={{ height: 'auto', width: '100%', maxWidth: '615px' }}
                            />
                        </motion.div>
                    </Box>
                </Box>
            </Container>
        </div >
    );
}

export default LandingSection;



// import React, { useEffect, useState } from "react";
// import {
//     Box,
//     Container,
//     Typography,
//     Grid,
//     Button,
//     makeStyles,
//     useTheme,
//     useMediaQuery,
// } from "@material-ui/core";
// import { useInView } from "react-intersection-observer";
// import { motion } from "framer-motion";
// import UseStoreLink from "./UseStoreLink";

// function LandingSection({ offset, handleShowModal }) {
//     const [refreshToken, setRefreshToken] = useState("");

//     useEffect(() => {
//         setRefreshToken(localStorage.getItem("refresh_token"));
//     }, []);

//     const useStyles = makeStyles((theme) => ({
//         root: {
//             padding: "4rem 0",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             flexDirection: "column",
//             background: "linear-gradient(90deg, #E9F4FF 18.12%, #F8FAFC 100%)",
//             textAlign: "center",
//         },
//         headingOne: {
//             fontSize: "2.5rem",
//             fontWeight: 500,
//             color: "#00AEEF",
//         },
//         headingOneColor: {
//             color: "#0E76BC",
//         },
//         headingOneParagraph: {
//             fontSize: "1.5rem",
//             fontWeight: 600,
//             color: "#000",
//             marginBottom: "1rem",
//         },
//         headingOneParagraphSub: {
//             fontSize: "1rem",
//             fontWeight: 400,
//             color: "#000",
//             marginBottom: "1.5rem",
//         },
//         buttonStartDownload: {
//             padding: "12px 20px",
//             width: "100%",
//             maxWidth: "230px",
//             background: "#0E76BC",
//             color: "#fff",
//             borderRadius: "25px",
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             cursor: "pointer",
//             border: "1px solid #828282",
//             textTransform: "capitalize",
//             "&:hover": {
//                 background: "#00AEEF",
//                 border: "1px solid #0E76BC",
//             },
//         },
//         buttonExplore: {
//             padding: "12px 20px",
//             width: "100%",
//             maxWidth: "230px",
//             background: "#fff",
//             color: "#000",
//             borderRadius: "25px",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             cursor: "pointer",
//             border: "1px solid #828282",
//             textTransform: "capitalize",
//             "&:hover": {
//                 background: "#E9F4FF",
//                 border: "1px solid #0E76BC",
//             },
//         },
//         gridContainer: {
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             flexDirection: "row",
//             [theme.breakpoints.down("sm")]: {
//                 flexDirection: "column",
//             },
//         },
//         gridItem: {
//             display: "flex",
//             justifyContent: "center",
//             width: "100%",
//         },
//         imgContainer: {
//             textAlign: "center",
//             marginTop: "2rem",
//             "& img": {
//                 maxWidth: "90%",
//                 height: "auto",
//             },
//         },
//         "@media (max-width: 1024px)": {
//             root: {
//                 padding: "4rem 0",
//             },
//             headingOne: {
//                 fontSize: "2rem",
//             },
//             headingOneParagraph: {
//                 fontSize: "1.3rem",
//             },
//             headingOneParagraphSub: {
//                 fontSize: "0.9rem",
//             },
//         },
//         "@media (max-width: 768px)": {
//             headingOne: {
//                 fontSize: "1.8rem",
//             },
//             headingOneParagraph: {
//                 fontSize: "1.2rem",
//             },
//             headingOneParagraphSub: {
//                 fontSize: "0.85rem",
//             },
//             buttonStartDownload: {
//                 maxWidth: "200px",
//             },
//             buttonExplore: {
//                 maxWidth: "200px",
//             },
//         },
//         "@media (max-width: 480px)": {
//             headingOne: {
//                 fontSize: "1.6rem",
//             },
//             headingOneParagraph: {
//                 fontSize: "1rem",
//             },
//             headingOneParagraphSub: {
//                 fontSize: "0.8rem",
//             },
//             buttonStartDownload: {
//                 maxWidth: "180px",
//             },
//             buttonExplore: {
//                 maxWidth: "180px",
//             },
//         },
//     }));

//     const classes = useStyles();
//     const storeLink = UseStoreLink();
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//     const { ref: sectionRef, inView: sectionInView } = useInView({
//         triggerOnce: true,
//         threshold: 0.5,
//     });

//     return (
//         <div className={classes.root}>
//             <Container maxWidth="lg">
//                 <motion.div ref={sectionRef} initial={{ opacity: 0 }} animate={{ opacity: sectionInView ? 1 : 0 }} transition={{ duration: 1 }}>
//                     <Box>
//                         <Typography variant="h6" className={classes.headingOne}>
//                             <span className={classes.headingOneColor}>MF</span> Wealth
//                         </Typography>
//                         <Typography variant="body2" className={classes.headingOneParagraph}>
//                             Your Wealth - Our Expertise
//                         </Typography>
//                         <Typography variant="body2" className={classes.headingOneParagraphSub}>
//                             Expert-curated mutual fund portfolios designed to maximize your returns effortlessly.
//                         </Typography>
//                     </Box>
//                 </motion.div>

//                 <Box margin={"auto 0px"}>
//                     <Grid container spacing={3} alignItems="center" className={classes.gridContainer}>
//                         <Grid item xs={12} md={6} className={classes.gridItem}>
//                             <motion.div initial={{ opacity: 0, y: 70 }} animate={{ opacity: sectionInView ? 1 : 0, y: sectionInView ? 0 : 50 }} transition={{ duration: 1 }}>
//                                 <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} style={{ background: "none", border: "none", width: "100%" }}>
//                                     <Button className={classes.buttonStartDownload} onClick={() => window.open(storeLink, "_blank")}>
//                                         Get Started
//                                     </Button>
//                                 </motion.button>
//                             </motion.div>
//                         </Grid>

//                         <Grid item xs={12} md={6} className={classes.gridItem}>
//                             <motion.div initial={{ opacity: 0, y: 70 }} animate={{ opacity: sectionInView ? 1 : 0, y: sectionInView ? 0 : 50 }} transition={{ duration: 1 }}>
//                                 <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} style={{ background: "none", border: "none", width: "100%" }}>
//                                     <Button className={classes.buttonExplore}>Explore Mutual Funds</Button>
//                                 </motion.button>
//                             </motion.div>
//                         </Grid>
//                     </Grid>
//                 </Box>

//                 <Box className={classes.imgContainer}>
//                     <motion.div initial={{ opacity: 0 }} animate={{ opacity: sectionInView ? 1 : 0 }} transition={{ duration: 1 }}>
//                         <img src="/images/Intro_Page/mfwealthLandingPage.svg" alt="Dashboard Illustration" className="img-fluid" />
//                     </motion.div>
//                 </Box>
//             </Container>
//         </div>
//     );
// }

// export default LandingSection;
