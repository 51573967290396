import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';

function LandingFifthSection({ offset, handleShowModal }) {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            // padding: "30px 0rem 68px 0rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: '0',
            // position: 'relative',
            // right: '5px',
            // Remove the linear gradient and add background image
            backgroundImage: 'url("/images/Intro_Page/How it works.svg")',
            backgroundSize: 'cover', // Changed to cover the entire area
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
            width: '101%',
            height: '830px',
        },
        headingOne: {
            fontSize: "40px",
            fontWeight: 500,
            color: "#FFFFFF",
            textAlign: "center",
            position: 'relative',
            top: '80px',
            display: 'flex',
            justifyContent: 'center',
            // bottom: '50px',
        },
        headingOneColor: {
            color: "#0E76BC",
        },
        headingOneParagraph: {
            fontSize: "32px",
            fontWeight: 600,
            color: "#000000",
            textAlign: "center",
        },
        buttonStartDownload: {
            padding: "15px 13px",
            background: "#0E76BC",
            color: "#fff",
            borderRadius: "25px",
            cursor: "pointer",
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#00AEEF",
            },
        },
        buttonExplore: {
            padding: "15px 13px",
            background: "#fff",
            color: "#000000",
            borderRadius: "25px",
            cursor: "pointer",
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#E9F4FF",
            },
        },
        MainText: {
            fontSize: '48px',
            fontWeight: 600,
            color: '#FFFFFF',
        },
        subText: {
            fontSize: "16px",
            fontWeight: 500,
            color: '#FFFFFF',

        },
        "@media (max-width: 1024px)": {
            // root: {
            //     backgroundImage: 'url("/images/Intro_Page/Design your portfolio.svg")',
            // }
        },
        "@media (max-width: 768px)": {
            // root: {
            //     backgroundImage: 'url("/images/Intro_Page/Design your portfolio.svg")',
            // }

        },
        "@media (max-width: 480px)": {
            headingOne: {
                padding: '0 20px',
                fontSize: "24px",
                textAlign: "left",
                // justifyContent: "start"
            },
            root: {
                backgroundImage: 'url("/images/Intro_Page/Design your portfolio.svg")',
            }
        }
    }));

    const classes = useStyles();

    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.3 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: { opacity: 1, x: 0 },
    };

    return (
        <Box overflow={'hidden'} style={{ background: '#161616', height: 'auto' }}>
            {/* <Box height={'50px'}>
                <Container maxWidth="lg" >
                   
                </Container>
            </Box> */}
            <motion.div
                ref={sectionRef}
                initial="hidden"
                animate={sectionInView ? "visible" : "hidden"}
                variants={containerVariants}
                style={{ width: '100%' }}
                className={classes.root}
            >
                <motion.div variants={itemVariants} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                    <Typography variant="h6" className={classes.headingOne} >
                        How do we design your portfolio?
                    </Typography>
                </motion.div>
                <div
                    // ref={sectionRef}
                    // initial="hidden"
                    // animate={sectionInView ? "visible" : "hidden"}
                    // variants={containerVariants}
                    style={{ width: '100%' }}
                    className={classes.root}
                >
                </div>
            </motion.div>
            {/* </div> */}
        </Box >
    );
}

export default LandingFifthSection;