import React, { useEffect, useState } from "react";
import { Container, Typography, Grid, makeStyles, Button } from "@material-ui/core";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import UseStoreLink from "./UseStoreLink";

function LandingTenthSection() {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            // background: "linear-gradient(90deg, #000000 0%, #1a1a1a 100%)",
            background: "linear-gradient(180deg, #161616 61.5%, rgba(22, 22, 22, 0.8) 100%)",
            padding: "80px 0 0px 0",
            overflow: 'hidden',
            color: "#fff",
        },
        heading: {
            fontSize: "36px",
            fontWeight: 700,
            color: "#fff",
            marginBottom: "30px",
            lineHeight: "1.3",
        },
        highlightText: {
            color: "#00AEEF",
        },
        button: {
            backgroundColor: "#00AEEF",
            color: "#fff",
            textTransform: "none",
            padding: "12px 24px",
            borderRadius: "30px",
            fontSize: "16px",
            fontWeight: 600,
            "&:hover": {
                backgroundColor: "#008bb7",
            },
        },
        appImage: {
            width: "100%",
            height: "auto",
            objectFit: "cover",
            position: 'relative',
            top: '82px',
        },
        "@media (max-width: 480px)": {
            appImage: {
                top: '18px',

            },
        }
    }));

    const classes = useStyles();
    const storeLink = UseStoreLink();

    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const itemVariants = {
        hidden: { opacity: 0, y: 70 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div
            ref={sectionRef}
            initial="hidden"
            animate={sectionInView ? "visible" : "hidden"}
            variants={{
                visible: { transition: { staggerChildren: 0.2 } },
                hidden: {},
            }}
            className={classes.root}
        >
            <Container maxWidth="lg" style={{ height: '80vh' }}>
                <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <motion.div variants={itemVariants}>
                            <Typography className={classes.heading}>
                                It’s time to <span className={classes.highlightText}>Grow</span> <br />
                                <span className={classes.highlightText}>Your Wealth</span>
                            </Typography>
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                style={{ background: 'none', border: 'none' }}
                            >
                                <Button
                                    className={classes.button}
                                    onClick={() => window.open(storeLink, "_blank")} // Open in a new tab
                                    endIcon={
                                        <>
                                            {/* // <div style{{display={'flex'} alignItems={'center'} justifyContent={'center'} marginLeft={2}> */}
                                            <img src='/images/Intro_Page/mage_playstore.svg' alt='download' width={'auto'} height={'18px'} />
                                            <img src='/images/Intro_Page/paystore_appstore_split.svg' alt='download' width={'auto'} height={'18px'} style={{ margin: "0px 4px" }} />
                                            <img src='/images/Intro_Page/simple-icons_appstore.svg' alt='download' width={'auto'} height={'18px'} />
                                            {/* // </div> */}
                                        </>
                                    }
                                >
                                    Download App
                                </Button>
                            </motion.button>
                        </motion.div>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: '0px' }}>
                        <motion.div variants={itemVariants}>
                            <img
                                src="/images/Intro_Page/Group 1000002349.svg" // Update with your actual image path
                                alt="App Mockup"
                                className={classes.appImage}
                            />
                        </motion.div>
                    </Grid>
                </Grid>
            </Container>
        </motion.div>
    );
}

export default LandingTenthSection;
