import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';

function LandingSecondSection({ offset, handleShowModal }) {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            // padding: "30px 0rem 68px 0rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: '0',
            // position: 'relative',
            // right: '5px',
            // Remove the linear gradient and add background image
            backgroundImage: 'url("/images/Intro_Page/Mid_bar.svg")',
            backgroundSize: 'cover', // Changed to cover the entire area
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
            width: '101%',
            height: '180px',
        },
        headingOne: {
            fontSize: "40px",
            fontWeight: 500,
            color: "#00AEEF",
            textAlign: "center",
        },
        headingOneColor: {
            color: "#0E76BC",
        },
        headingOneParagraph: {
            fontSize: "32px",
            fontWeight: 600,
            color: "#000000",
            textAlign: "center",
        },
        buttonStartDownload: {
            padding: "15px 13px",
            background: "#0E76BC",
            color: "#fff",
            borderRadius: "25px",
            cursor: "pointer",
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#00AEEF",
            },
        },
        buttonExplore: {
            padding: "15px 13px",
            background: "#fff",
            color: "#000000",
            borderRadius: "25px",
            cursor: "pointer",
            border: "1px solid #828282",
            textTransform: "capitalize",
            "&:hover": {
                background: "#E9F4FF",
            },
        },
        MainText: {
            fontSize: '48px',
            fontWeight: 600,
            color: '#FFFFFF',
        },
        subText: {
            fontSize: "16px",
            fontWeight: 500,
            color: '#FFFFFF',

        },
        "@media (max-width: 1024px)": {
            root: {
                height: '280px',
                backgroundImage: 'url("/images/Intro_Page/Mid bar mobile.svg")',
            }
        },
        "@media (max-width: 768px)": {
            root: {
                height: '400px',
                backgroundImage: 'url("/images/Intro_Page/Mid bar mobile.svg")',
            },
            MainText: {
                fontSize: '40px',
            },
            subText: {
                fontSize: "12px",
            },

        },
        "@media (max-width: 480px)": {
            root: {
                height: '400px',
                backgroundImage: 'url("/images/Intro_Page/Mid bar mobile.svg")',
            }
        }
    }));

    const classes = useStyles();

    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { staggerChildren: 0.3 } },
    };

    const itemVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: { opacity: 1, x: 0 },
    };

    return (
        <Box overflow={'hidden'} style={{ background: '#0E76BC' }}>
            <div className={classes.root}>
                <motion.div
                    ref={sectionRef}
                    initial="hidden"
                    animate={sectionInView ? "visible" : "hidden"}
                    variants={containerVariants}
                    style={{ width: '100%' }}

                >
                    {sectionInView &&
                        <Container maxWidth="lg" >
                            <Box display={'flex'} alignItems={'center'} >
                                <Grid container spacing={4} alignItems="center">
                                    {/* <Grid item xs={12} sm={6} md={4} >
                                        <motion.div variants={itemVariants} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <span className={classes.MainText}>
                                                <CountUp end={18} duration={3} /> %
                                            </span>
                                            <span className={classes.subText}>Average Returns </span>
                                        </motion.div>
                                    </Grid> */}
                                    <Grid item xs={12} sm={6} md={6} >
                                        <motion.div variants={itemVariants} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <span className={classes.MainText}>
                                                <CountUp end={500} duration={3} />+ cr
                                            </span>
                                            <span className={classes.subText}>Assets Under Management</span>
                                        </motion.div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} >
                                        <motion.div variants={itemVariants} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <span className={classes.MainText}>
                                                <CountUp end={2000} duration={3} /> +
                                            </span>
                                            <span className={classes.subText}> Investor multiplying their wealth </span>
                                        </motion.div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    }
                </motion.div>
            </div>
        </Box>
    );
}

export default LandingSecondSection;