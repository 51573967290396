import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { namespace } from '../../environment';
import { useNavigate } from 'react-router-dom';

function LandingSeventhSection() {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: "60px 0",
            background: "#0E76BC",
            color: "#fff",
            textAlign: "center",
            backgroundImage: 'url("/images/Intro_Page/Section 17.svg")',
            backgroundSize: 'cover', // Changed to cover the entire area
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center top',
            // maxHeight: '600px',
            height: '70dvh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // margin: "auto 0",
        },
        heading: {
            fontSize: "32px",
            fontWeight: 700,
            marginBottom: "20px",
        },
        description: {
            fontSize: "16px",
            fontWeight: 400,
            marginBottom: "40px",
            maxWidth: "700px",
            margin: "0 auto 40px",
            color: "#F1F1F1",
        },
        partnerBox: {
            display: "flex",
            justifyContent: "center",
            gap: "40px",
            marginBottom: "40px",
            flexWrap: "wrap",
        },
        partnerLogo: {
            background: "#fff",
            borderRadius: "16px",
            padding: "20px",
            width: "140px",
            height: "140px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        },
        img: {
            maxWidth: "100%",
            maxHeight: "100%",
        },
        button: {
            padding: "14px 24px",
            background: "#fff",
            color: "#0E76BC",
            borderRadius: "25px",
            textTransform: "capitalize",
            fontWeight: 600,
            "&:hover": {
                background: "#f1f1f1",
            },
        },
        "@media (max-width: 1024px)": {
            root: {
                height: '100dvh',
            }
        },
        "@media (max-width: 768px)": {
        },
        "@media (max-width: 480px)": {
            partnerLogo: {
                width: "60%",
                height: "auto",

            },
        }
    }));

    const classes = useStyles();
    const navigate = useNavigate();


    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const itemVariants = {
        hidden: { opacity: 0, y: 30 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div ref={sectionRef} initial="hidden" animate={sectionInView ? "visible" : "hidden"} variants={{
            visible: { transition: { staggerChildren: 0.2 } },
            hidden: {}
        }} className={classes.root}>
            <Container maxWidth="lg">
                <motion.div variants={itemVariants}>
                    <Typography className={classes.heading}>
                        Our Trusted Partners
                    </Typography>
                </motion.div>
                <motion.div variants={itemVariants}>
                    <Typography className={classes.description}>
                        We have partnered with BSE Star MF and NSE Mutual Fund Platform to provide you with a trusted and technology-driven platform for all your mutual fund needs.
                    </Typography>
                </motion.div>
                <motion.div variants={itemVariants} className={classes.partnerBox}>
                    <div className={classes.partnerLogo}>
                        <img src="/images/Intro_Page/Frame 1618871589.svg" alt="NSE" className={classes.img} />
                    </div>
                    <div className={classes.partnerLogo}>
                        <img src="/images/Intro_Page/Frame 1618871590.svg" alt="BSE" className={classes.img} />
                    </div>
                </motion.div>
                <motion.div variants={itemVariants}>
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                        style={{ background: 'none', border: 'none', width: '100%', display: 'flex', justifyContent: 'center' }}
                    >
                        <Button className={classes.button}
                            onClick={() => {
                                navigate(`${namespace}/user`);
                            }}>
                            Track now <ArrowForwardIcon style={{ marginLeft: '5px' }} />
                        </Button>
                    </motion.button>
                </motion.div>
            </Container>
        </motion.div>
    );
}

export default LandingSeventhSection;
