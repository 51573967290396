import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function LandingEigthSection() {
    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        setRefreshToken(localStorage.getItem("refresh_token"));
    }, []);

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: "60px 0",
            background: "#F8FAFC",
        },
        heading: {
            fontSize: "36px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "40px",
            color: "#0E76BC",
        },
        tableContainer: {
            background: "#F8FAFC",
            borderRadius: "12px",
            // boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
            overflow: "hidden",
            width: "100%",
        },
        row: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            borderBottom: "1px solid #A6A6A6",
            alignItems: "start",
            // padding: "20px",
            // [theme.breakpoints.down("sm")]: {
            //     gridTemplateColumns: "1fr",
            //     rowGap: "12px",
            // },
        },
        headerRow: {
            background: "#F8FAFC",
            fontWeight: 600,
        },
        cellHead: {
            padding: "20px",
            color: "#333",
            fontSize: "24px",
            fontWeight: 600,
        },
        cell: {
            // padding: "4px 0",
            padding: "20px",
            color: "#333",
        },
        feature: {
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            color: "#000",
            margin: "auto 0",
        },
        checkIcon: {
            color: "#0E76BC",
            marginRight: "8px",
            verticalAlign: "middle",
        },
        cancelIcon: {
            color: "#757575",
            marginRight: "8px",
            verticalAlign: "middle",
        },
        tableDataOther: {
            color: "#757575",
        },
        "@media (max-width: 1024px)": {

        },
        "@media (max-width: 768px)": {
            tableContainer: {
                minWidth: '700px ',
                overflowX: "auto",
            }
        },
        "@media (max-width: 480px)": {
            cellHead: {
              
                fontSize: "20px",
            },
            cell: {
                fontSize: "10px",
            },
        }
    }));

    const classes = useStyles();

    const { ref: sectionRef, inView: sectionInView } = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const tableData = [
        {
            feature: "Personalisation",
            mfWealth: (
                <Box display={'flex'} alignItems={'center'}>
                    <CheckCircleIcon className={classes.checkIcon} />
                    <Typography className={classes.tableData}>
                        Portfolio tailored to your unique investing style and financial goals
                    </Typography>
                </Box>
            ),
            others: (
                <Box display={'flex'} alignItems={'center'}>
                    <CancelIcon className={classes.cancelIcon} />
                    <Typography className={classes.tableDataOther}>
                        Generic portfolios with no personalisation
                    </Typography>

                </Box>
            ),
        },
        {
            feature: "Dedicated Support",
            mfWealth: (
                <Box display={'flex'} alignItems={'center'}>
                    <CheckCircleIcon className={classes.checkIcon} />
                    <Typography className={classes.tableData}>
                        A dedicated relationship manager for every investor, regardless of portfolio size
                    </Typography>
                </Box>
            ),
            others: (
                <Box display={'flex'} alignItems={'center'}>
                    <CancelIcon className={classes.cancelIcon} />
                    <Typography className={classes.tableDataOther}>
                        Relationship managers are often reserved only for high-net-worth individuals (HNIs)
                    </Typography>
                </Box>
            ),
        },
        {
            feature: "Rebalancing",
            mfWealth: (
                <Box display={'flex'} alignItems={'center'}>
                    <CheckCircleIcon className={classes.checkIcon} />
                    <Typography className={classes.tableData}>
                        Annual rebalancing based on market conditions to optimize returns
                    </Typography>
                </Box>
            ),
            others: (
                <Box display={'flex'} alignItems={'center'}>
                    <CancelIcon className={classes.cancelIcon} />
                    <Typography className={classes.tableDataOther}>
                        No rebalancing feature, leaving portfolios stagnant
                    </Typography>
                </Box>
            ),
        },
        {
            feature: "Dynamic SIP",
            mfWealth: (
                <Box display={'flex'} alignItems={'center'}>
                    <CheckCircleIcon className={classes.checkIcon} />
                    <Typography className={classes.tableData}>
                        Adjust asset allocation dynamically based on market trends and opportunities
                    </Typography>
                </Box>
            ),
            others: (
                <Box display={'flex'} alignItems={'center'}>
                    <CancelIcon className={classes.cancelIcon} />
                    <Typography className={classes.tableDataOther}>
                        No dynamic SIP feature or market-responsive adjustments
                    </Typography>
                </Box>
            ),
        },
        {
            feature: "Fees",
            mfWealth: (
                <Box display={'flex'} alignItems={'center'}>
                    <CheckCircleIcon className={classes.checkIcon} />
                    <Typography className={classes.tableData}>
                        0 hidden fees or charges – invest with complete transparency
                    </Typography>
                </Box>
            ),
            others: (
                <Box display={'flex'} alignItems={'center'}>
                    <CancelIcon className={classes.cancelIcon} />
                    <Typography className={classes.tableDataOther}>
                        Hidden fees, profit-sharing, or platform charges that eat into your returns
                    </Typography>
                </Box>
            ),
        },
    ];

    const itemVariants = {
        hidden: { opacity: 0, y: 40 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <motion.div
            ref={sectionRef}
            initial="hidden"
            animate={sectionInView ? "visible" : "hidden"}
            variants={{
                visible: { transition: { staggerChildren: 0.2 } },
                hidden: {},
            }}
            className={classes.root}
        >
            <Container maxWidth="lg">
                <motion.div variants={itemVariants}>
                    <Typography className={classes.heading}>
                        MF Wealth <span style={{ color: "#000" }}>vs. Others</span>
                    </Typography>
                </motion.div>
                <motion.div variants={itemVariants} >
                    <Box sx={{ overflowX: 'auto', width: '100%' }}> 
                        <div className={classes.tableContainer}>
                            <div className={`${classes.row} ${classes.headerRow}`} style={{ border: 'none' }}>
                                <div className={classes.cellHead}></div>
                                <div className={`${classes.cellHead} `} style={{ backgroundColor: '#E9F4FF80', color: '#0E76BC' }}>MF Wealth</div>
                                <div className={classes.cellHead}>Other investing platforms</div>
                            </div>
                            {tableData.map((row, index) => (
                                <div key={index} className={classes.row}
                                    style={{
                                        border: tableData?.length - 1 === index && 'none'
                                    }}>
                                    <div className={`${classes.cellHead} ${classes.feature}`}>{row.feature}</div>
                                    <div className={classes.cell} style={{ backgroundColor: '#E9F4FF80' }}>{row.mfWealth}</div>
                                    <div className={classes.cell}>{row.others}</div>
                                </div>
                            ))}
                        </div>
                    </Box>
                </motion.div>
            </Container>
        </motion.div>
    );
}

export default LandingEigthSection;
